import { DateTime } from 'luxon';

export const getPossibleDates = (
    possibleEventDates: string[],
    timezone: string
): DateTime[] =>
    possibleEventDates.map(date =>
        DateTime.fromFormat(date, 'yyyy-LL-dd', { zone: timezone })
    );

export const getPossibleTimes = (
    startTime: string,
    endTime: string,
    timezone: string
): DateTime[] => {
    const endTimeIsOnNextDay = endTime <= startTime;

    const startTimeDT = DateTime.fromFormat(
        `2020-06-04 ${startTime}`,
        'yyyy-LL-dd HH:mm',
        { zone: timezone }
    );

    const endTimeDT = DateTime.fromFormat(
        `${endTimeIsOnNextDay ? '2020-06-05' : '2020-06-04'} ${endTime}`,
        'yyyy-LL-dd HH:mm',
        { zone: timezone }
    );

    const times: DateTime[] = [];
    for (let i = startTimeDT; i <= endTimeDT; i = i.plus({ minutes: 15 })) {
        times.push(i);
    }

    return times;
};
