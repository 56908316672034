import {
    CSSProperties,
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import { DateTime } from 'luxon';

import { Colors } from 'src/utils';
import { HoveredTimeBlock } from '..';
import {
    useAvailabilitiesByDateQuery,
    UserProfileFragment
} from 'src/graphql/generated/artifacts';
import { useIsDarkMode } from 'src/hooks';

interface Props {
    eventId: string;
    usesWeekdays: boolean;
    date: DateTime;
    possibleTimes: DateTime[];
    selectedUserIds: string[];
    colorGradient: string[];
    setHoveredTimeBlock: Dispatch<SetStateAction<HoveredTimeBlock | null>>;
}

const TimeDisplayBar = (props: Props) => {
    const {
        eventId,
        usesWeekdays,
        date,
        possibleTimes,
        selectedUserIds,
        colorGradient,
        setHoveredTimeBlock
    } = props;

    // time summaries indexed by date time (yyyy-LL-ddTHH:mm)
    // we store these in a map to make checking values faster
    // (these include all unfiltered availabilities of all users
    // associated with the event)
    const [availabilities, setAvailabilities] = useState<
        Map<string, UserProfileFragment[]>
    >(new Map());

    const { data, loading, error } = useAvailabilitiesByDateQuery({
        variables: { eventId, date: date.toFormat('yyyy-LL-dd') }
    });

    const isDarkMode = useIsDarkMode();

    useEffect(() => {
        if (data?.availabilitiesByDate) {
            const newAvailabilities = new Map();
            data.availabilitiesByDate.forEach(summary => {
                newAvailabilities.set(
                    summary.dateTime,
                    summary.availablePeople
                );
            });
            setAvailabilities(newAvailabilities);
        }
    }, [data]);

    const onHover = (index: number) => {
        const startTime = possibleTimes[index];
        const endTime = startTime.plus({ minutes: 15 });
        const dateTime =
            date.toFormat("yyyy-LL-dd'T'") + startTime.toFormat('HH:mm');

        setHoveredTimeBlock({
            startTime,
            endTime,
            date,
            availableUsers: availabilities.has(dateTime)
                ? (availabilities.get(dateTime) as UserProfileFragment[])
                : []
        });
    };

    const styles = coloredStyles(isDarkMode);

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            {usesWeekdays ? (
                <DateSpacer />
            ) : (
                <p style={styles.dateText}>{date.toFormat('LLL dd')}</p>
            )}
            <p style={styles.dayText}>{date.toFormat('ccc')}</p>
            <div style={styles.timeDisplayContainer}>
                {possibleTimes.slice(0, -1).map((time: DateTime, i: number) => {
                    const borderStyle = getBorderStyle(time, i);
                    const dateTime =
                        date.toFormat("yyyy-LL-dd'T'") + time.toFormat('HH:mm');
                    const blockColor = availabilities.has(dateTime)
                        ? getColor(
                              availabilities.get(
                                  dateTime
                              ) as UserProfileFragment[],
                              selectedUserIds,
                              colorGradient
                          )
                        : styles.emptyColor;
                    return (
                        <div
                            key={i}
                            onMouseOver={() => onHover(i)}
                            onClick={() => onHover(i)}
                            onMouseLeave={() => setHoveredTimeBlock(null)}
                            style={{
                                ...styles.timeBlock,
                                ...borderStyle,
                                background: blockColor
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const getColor = (
    availablePeople: UserProfileFragment[],
    selectedUserIds: string[],
    colorGradient: string[]
) => {
    const selectedUserIdsSet = new Set(selectedUserIds);
    const filteredAvailablePeople = availablePeople.filter(user =>
        selectedUserIdsSet.has(user.id)
    );
    const count = filteredAvailablePeople.length;
    return colorGradient[count];
};

const getBorderStyle = (time: DateTime, index: number) => {
    switch (time.toFormat('mm')) {
        case '00':
            if (index === 0) {
                return {};
            } else {
                return {
                    borderTopStyle: 'solid',
                    borderTopWidth: 1
                } as CSSProperties;
            }
        case '30':
            return {
                borderTopStyle: 'dotted',
                borderTopWidth: 1
            } as CSSProperties;
        default:
            return { height: 9 };
    }
};

const DateSpacer = () => <div style={{ height: 12 }} />;

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        width: 43,
        textAlign: 'center',
        display: 'inline-block',
        verticalAlign: 'center',
        margin: '5px 4px 10px 4px'
    } as CSSProperties,
    dateText: {
        fontFamily: 'Futura-Book',
        fontSize: 10,
        color: isDarkMode ? Colors.white : '#3C3C3C',
        margin: 0,
        lineHeight: '1.2'
    } as CSSProperties,
    dayText: {
        fontFamily: 'Futura-Heavy',
        fontSize: 18,
        color: isDarkMode ? Colors.white : '#3C3C3C',
        margin: '0 0 5px 0',
        lineHeight: '1.2'
    } as CSSProperties,
    timeDisplayContainer: {
        width: '100%',
        border: `1px solid ${isDarkMode ? Colors.dusk : Colors.black}`,
        touchAction: 'pan-x'
    } as CSSProperties,
    timeBlock: {
        width: '100%',
        height: 8,
        position: 'relative',
        borderColor: isDarkMode ? Colors.dusk : Colors.black
    } as CSSProperties,
    emptyColor: isDarkMode ? Colors.night : Colors.white
});

export default TimeDisplayBar;
