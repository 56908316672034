import './styles.css';

import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

type FadeInProps = {
    children: ReactNode;
};

const FadeIn: FunctionComponent<FadeInProps> = (props: FadeInProps) => {
    const [fadeIn, setFadeIn] = useState<boolean>(false);
    useEffect(() => setFadeIn(true), []);
    return (
        <CSSTransition
            in={fadeIn}
            timeout={800}
            classNames="content-fade-in"
            onEntered={() => setFadeIn(false)}
        >
            {props.children}
        </CSSTransition>
    );
};

export default FadeIn;
