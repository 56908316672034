import { createTheme } from '@material-ui/core/styles';
import { CSSProperties } from 'react';
import { Colors } from 'src/utils';

const BREAKPOINT = 1000;

export const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    contentContainer: {
        zIndex: 1,
        display: 'inline-block',
        position: 'relative',
        margin: '120px 0 60px 0',
        width: windowWidth > 1200 ? 1200 : 'calc(100% - 60px)',
        background: isDarkMode ? Colors.midnight : Colors.white,
        borderRadius: 25,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    headerText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    formWrap: {
        width: windowWidth > BREAKPOINT ? '75%' : '100%',
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'left',
        borderRight: windowWidth > BREAKPOINT ? '1px solid' : 'none',
        borderColor: isDarkMode ? Colors.dusk : 'rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    adWrap: {
        width: 'calc(25% - 1px)',
        height: '100%',
        display: windowWidth > BREAKPOINT ? 'inline-block' : 'none',
        verticalAlign: 'top',
        textAlign: 'left',
        padding: '20px 30px 20px 30px',
        overflow: 'hidden'
    } as CSSProperties,
    formTitleWrap: {
        width: '100%',
        padding: '20px 30px 20px 30px',
        borderBottom: '1px solid',
        borderColor: isDarkMode ? Colors.dusk : 'rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    formFields: {
        width: '100%',
        padding: '10px 30px 80px 30px',
        textAlign: 'center'
    } as CSSProperties,
    field: {
        marginTop: 60,
        width: windowWidth > BREAKPOINT ? 300 : '100%'
    } as CSSProperties,
    dateDaysToggle: {
        marginTop: 60
    } as CSSProperties,
    infiniteCalendarWrap: {
        marginTop: 30,
        display: 'inline-block'
    } as CSSProperties,
    infiniteCalendar: {
        accentColor: Colors.secondary,
        headerColor: Colors.primary,
        weekdayColor: Colors.secondary,
        selectionColor: Colors.secondary,
        floatingNav: { background: Colors.primary, color: Colors.white }
    },
    weekdayPickerWrap: {
        marginTop: 30,
        display: 'inline-block'
    } as CSSProperties,
    buttonStyle: {
        display: 'inline-block',
        width: 180,
        height: 48,
        margin: '60px 10px 0px 10px',
        padding: 0,
        textAlign: 'center',
        boxShadow: 'none',
        borderRadius: 30
    } as CSSProperties,
    buttonHoverStyle: {
        background: Colors.secondary
    } as CSSProperties
});

export const theme = (isDarkMode: boolean) =>
    createTheme({
        palette: {
            ...(isDarkMode && { type: 'dark' }),
            primary: { main: Colors.primary, contrastText: '#fff' }
        },
        typography: { fontFamily: 'Futura-Medium' }
    });
