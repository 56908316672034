import { CSSProperties, useEffect, useState } from 'react';
import { prop } from 'lodash/fp';
import { useWindowWidth } from '@react-hook/window-size';

import { Colors } from 'src/utils';
import { HoveredTimeBlock } from '..';
import OwlImage from 'src/assets/img/owl.png';
import { Text } from 'src/SharedComponents';
import {
    useEventByIdQuery,
    UserProfileFragment
} from 'src/graphql/generated/artifacts';
import { useIsDarkMode } from 'src/hooks';

const BREAKPOINT = 900;
const SMALL_BREAKPOINT = 600;

interface Props {
    eventId: string;
    hoveredTimeBlock: HoveredTimeBlock | null;
    selectedUserIds: string[];
    selectedTimezone: string;
}

const AvailablePeopleBox = (props: Props) => {
    const { eventId, hoveredTimeBlock, selectedUserIds, selectedTimezone } =
        props;

    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    const [availableUsers, setAvailableUsers] = useState<UserProfileFragment[]>(
        []
    );
    const [unavailableUsers, setUnavailableUsers] = useState<
        UserProfileFragment[]
    >([]);

    const { loading, data, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    const showAvailabilities: boolean = availableUsers.length !== 0;

    useEffect(() => {
        if (hoveredTimeBlock && prop('eventById.participants', data)) {
            const selectedUserIdSet = new Set(selectedUserIds);
            const newAvailableUsers = hoveredTimeBlock.availableUsers.filter(
                (user: UserProfileFragment) => selectedUserIdSet.has(user.id)
            );

            const availableSet = new Set(newAvailableUsers.map(u => u.id));
            const newUnavailableUsers: UserProfileFragment[] = (
                data?.eventById?.participants ?? []
            ).filter(
                user =>
                    selectedUserIdSet.has(user.id) && !availableSet.has(user.id)
            );

            setAvailableUsers(newAvailableUsers);
            setUnavailableUsers(newUnavailableUsers);
        }
    }, [data, hoveredTimeBlock, selectedUserIds]);

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            {showAvailabilities && hoveredTimeBlock ? (
                <>
                    <Text bolder style={styles.headerText}>
                        {availableUsers.length} / {selectedUserIds.length}{' '}
                        people available
                    </Text>
                    <br />
                    <Text
                        small
                        bold
                        style={{
                            ...styles.headerText,
                            marginTop: 10
                        }}
                    >
                        {hoveredTimeBlock.date.toFormat(
                            data?.eventById?.usesWeekdays ? 'ccc' : 'LLL dd ccc'
                        )}
                    </Text>
                    <br />
                    <Text
                        small
                        bold
                        style={{
                            ...styles.headerText,
                            marginTop: 10
                        }}
                    >
                        {hoveredTimeBlock.startTime
                            .setZone(selectedTimezone)
                            .toFormat('h:mm a')}{' '}
                        -{' '}
                        {hoveredTimeBlock.endTime
                            .setZone(selectedTimezone)
                            .toFormat('h:mm a')}
                    </Text>
                    <div style={styles.availablePeopleContainer}>
                        <div style={styles.nameListWrap}>
                            <div style={styles.availableLabelWrap}>
                                <Text small bold color={Colors.puertorico}>
                                    Available
                                </Text>
                            </div>
                            <br />
                            <ul style={styles.nameList}>
                                {availableUsers.map(
                                    (user: UserProfileFragment) => (
                                        <li key={user.id}>
                                            <Text
                                                bold
                                                small
                                                style={styles.text}
                                            >
                                                {user.name}
                                            </Text>
                                            <br />
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        <div style={styles.nameListWrap}>
                            <div style={styles.unavailableLabelWrap}>
                                <Text small bold color={Colors.mojo}>
                                    Unavailable
                                </Text>
                            </div>
                            <br />
                            <ul style={styles.nameList}>
                                {unavailableUsers.map(
                                    (user: UserProfileFragment) => (
                                        <li key={user.id}>
                                            <Text
                                                bold
                                                small
                                                style={styles.text}
                                            >
                                                {user.name}
                                            </Text>
                                            <br />
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Text bolder large style={styles.headerText}>
                        Hoot{' '}
                        <span style={{ color: Colors.secondary }}>hoot!</span>
                    </Text>
                    <br />
                    <Text style={{ ...styles.text, marginTop: 10 }}>
                        I'm Vaila! To see who's available at a certain time,
                        hover over or tap it and I'll tell you in this box.
                    </Text>
                    <br />
                    <img src={OwlImage} style={styles.owlStyle} alt="" />
                </>
            )}
        </div>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        display: 'inline-block',
        verticalAlign: 'top',
        maxWidth: 320,
        width: windowWidth > SMALL_BREAKPOINT ? 320 : '100%',
        margin: windowWidth > BREAKPOINT ? '80px 0 0 20px' : '30px 0 0 0',
        padding: 30,
        background: isDarkMode ? Colors.night : '#F6F7FB',
        border: 'solid 1px',
        borderColor: isDarkMode ? Colors.dusk : 'rgba(112, 112, 112, 0.2)',
        borderRadius: 20,
        textAlign: 'center'
    } as CSSProperties,
    headerText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    owlStyle: {
        width: 100,
        marginTop: 30
    } as CSSProperties,
    availablePeopleContainer: {
        width: '100%',
        paddingTop: 20
    } as CSSProperties,
    nameListWrap: {
        width: windowWidth > SMALL_BREAKPOINT ? '50%' : '100%',
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'left',
        padding: windowWidth > SMALL_BREAKPOINT ? 10 : '0 10px 0 10px'
    } as CSSProperties,
    availableLabelWrap: {
        maxWidth: 120,
        background: Colors.gossip,
        textAlign: 'center',
        borderRadius: 10,
        padding: 5
    } as CSSProperties,
    unavailableLabelWrap: {
        maxWidth: 120,
        background: Colors.cinderella,
        textAlign: 'center',
        borderRadius: 10,
        padding: 5
    } as CSSProperties,
    nameList: {
        marginTop: -15,
        listStyleType: 'none',
        textAlign: 'left',
        padding: '0 0 0 10px'
    } as CSSProperties
});

export default AvailablePeopleBox;
