import 'antd/lib/modal/style';

import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connectModal } from 'redux-modal';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

type Props = {
    header?: string;
    text?: string;
    isError?: boolean;
    maskClosable?: boolean;
    handleHide: () => void;
};

const SimpleAlertModal = ({
    header,
    text,
    isError,
    maskClosable,
    handleHide
}: Props) => {
    const isDarkMode = useIsDarkMode();
    return (
        <Modal
            visible={true}
            onCancel={handleHide}
            centered
            footer={null}
            width={600}
            className={
                isDarkMode ? 'vailable_antd_modal_dark' : 'vailable_antd_modal'
            }
            maskClosable={maskClosable ?? true}
        >
            <div>
                <Text
                    larger
                    bolder
                    color={isDarkMode ? Colors.white : Colors.offBlack}
                >
                    {header}
                </Text>
                <br />
                <Text
                    style={{
                        color: isDarkMode ? Colors.white : Colors.darkgray,
                        marginTop: 10
                    }}
                >
                    {text}
                </Text>
                {isError && <ErrorContact />}
            </div>
        </Modal>
    );
};

const ErrorContact = () => {
    const navigate = useNavigate();
    const isDarkMode = useIsDarkMode();
    return (
        <>
            <br />
            <Text
                style={{
                    color: isDarkMode ? Colors.white : Colors.darkgray,
                    marginTop: 10
                }}
            >
                If this problem persists,{' '}
                <Text
                    bold
                    onPress={() => navigate(`/contact`)}
                    color={Colors.secondary}
                    className="hover-darken"
                    style={{ cursor: 'pointer' }}
                >
                    contact us
                </Text>{' '}
                to let us know!
            </Text>
        </>
    );
};

export default connectModal({ name: 'SimpleAlertModal' })(SimpleAlertModal);
