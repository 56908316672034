import { compose } from 'lodash/fp';
import { show } from 'redux-modal';
import { useDetectAdBlock } from 'adblock-detect-react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useAdBlockDetectedModal = () => {
    const adBlockDetected = useDetectAdBlock();
    const dispatch = useDispatch();

    const _showModal = compose(dispatch, show);

    useEffect(() => {
        if (adBlockDetected) {
            _showModal('SimpleAlertModal', {
                header: 'AdBlock Detected 😢',
                text: 'We noticed you are running AdBlock. Please consider turning off AdBlock on our site to support the development and maintenance costs of hosting Vailable. Ads are what help us keep the app free to use. 💜',
                maskClosable: false
            });
        }
    }, [_showModal, adBlockDetected]);

    return null;
};

export default useAdBlockDetectedModal;
