import { useWindowWidth } from '@react-hook/window-size';
import { Switch } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VailableLogo from 'src/assets/logos/vailable.png';
import { PopOutMenu, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';

import AlertBanner from './AlertBanner';
import { updateLocalStorage } from './LocalStorage';
import TextPath from './TextPath';
import { setIsDarkMode } from 'src/state/reactiveVars';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const MOBILE_BREAKPOINT = 900;

interface textRoute {
    name: string;
    route: string;
    mobileIcon: string;
}

const textRoutes: textRoute[] = [
    {
        name: 'Tutorial',
        route: '/tutorial',
        mobileIcon: 'fas fa-chalkboard-teacher'
    },
    {
        name: 'About',
        route: '/about',
        mobileIcon: 'far fa-clock'
    },
    {
        name: 'Updates',
        route: '/updates',
        mobileIcon: 'fas fa-bullhorn'
    }
];

const NavBar = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();

    const [isOpenMenu, setOpenMenu] = useState<boolean>(false);
    const [showAlertBanner, setShowAlertBanner] = useState<boolean>(false);
    useEffect(() => {
        if (process.env.REACT_APP_ENABLE_ALERT_BANNER === 'true') {
            updateLocalStorage(setShowAlertBanner);
        }
    }, []);

    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    return (
        <>
            <div style={styles.container}>
                <ul style={styles.routes}>
                    <li style={styles.homePath}>
                        <img
                            src={VailableLogo}
                            onClick={() => navigate(`/`)}
                            style={styles.vailableLogo}
                            className="hover-darken"
                            alt=""
                        />
                    </li>
                    {windowWidth > MOBILE_BREAKPOINT &&
                        textRoutes.map((route: textRoute, i: number) => (
                            <TextPath
                                text={route.name}
                                route={route.route}
                                index={i}
                                key={i}
                            />
                        ))}
                    {windowWidth < MOBILE_BREAKPOINT && (
                        <PopOutMenu
                            isOpen={isOpenMenu}
                            setIsOpen={setOpenMenu}
                            title={
                                <Text bold>
                                    Vailable{' '}
                                    <i
                                        className="fas fa-heart"
                                        aria-hidden="true"
                                        style={{ color: Colors.primary }}
                                    />
                                </Text>
                            }
                            menuItems={textRoutes.map((route: textRoute) => ({
                                icon: (
                                    <i
                                        className={route.mobileIcon}
                                        aria-hidden="true"
                                        style={{
                                            color: Colors.primary,
                                            fontSize: 18
                                        }}
                                    />
                                ),
                                text: route.name,
                                onClick: () => navigate(route.route)
                            }))}
                            style={{
                                margin: '60px 50px 0 0',
                                width: 200
                            }}
                        />
                    )}
                    <li style={styles.switchWrap}>
                        <Switch
                            checked={isDarkMode}
                            onChange={() => setIsDarkMode(!isDarkMode)}
                            checkedChildren={<i className="fas fa-moon" />}
                            unCheckedChildren={<i className="fas fa-sun" />}
                        />
                    </li>
                    {windowWidth < MOBILE_BREAKPOINT && (
                        <i
                            className="fas fa-bars hover-purple"
                            onClick={() => setOpenMenu(!isOpenMenu)}
                            aria-hidden="true"
                            style={styles.menuToggle}
                        />
                    )}
                </ul>
            </div>
            {showAlertBanner && <AlertBanner />}
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        width: '100%',
        position: 'relative',
        zIndex: 2,
        background: isDarkMode ? 'none' : Colors.white,
        boxShadow: isDarkMode ? 'none' : '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    vailableLogo: {
        height: 40,
        margin: '5px 50px 10px 50px',
        cursor: 'pointer'
    } as CSSProperties,
    routes: {
        height: 80,
        padding: 0,
        margin: '0 auto',
        textAlign: 'center'
    } as CSSProperties,
    homePath: {
        verticalAlign: 'middle',
        display: 'inline-block',
        listStyle: 'none',
        ...(windowWidth < MOBILE_BREAKPOINT && {
            position: 'absolute',
            left: 0,
            top: 15
        })
    } as CSSProperties,
    menuToggle: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        fontSize: 18,
        position: 'absolute',
        top: 32,
        right: 60,
        cursor: 'pointer'
    } as CSSProperties,
    switchWrap: {
        verticalAlign: 'middle',
        display: 'inline-block',
        listStyle: 'none',
        padding: 30,
        ...(windowWidth < MOBILE_BREAKPOINT && {
            position: 'absolute',
            right: 80
        })
    } as CSSProperties
});

export default NavBar;
