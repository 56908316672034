import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties } from 'react';
import { BeatLoader } from 'react-spinners';
import FadeIn from 'src/components/FadeIn';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 800;

const Loader = () => {
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);
    return (
        <FadeIn>
            <div style={styles.container}>
                <Text large style={{ marginBottom: 20 }}>
                    Loading your dope event.
                </Text>
                <BeatLoader color={Colors.primary} size={15} />
            </div>
        </FadeIn>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        background: isDarkMode ? Colors.midnight : Colors.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: windowWidth > BREAKPOINT ? 80 : '80px 30px 80px 30px',
        width: windowWidth > BREAKPOINT ? 300 : 'calc(100% - 60px)',
        borderRadius: 25,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        zIndex: 2
    } as CSSProperties
});

export default Loader;
