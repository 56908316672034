import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
    TypePolicies
} from '@apollo/client';
import { ReactNode } from 'react';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL ?? 'http://localhost:8000/graphql'
});

const typePolicies: TypePolicies = {
    Query: {
        fields: {
            availabilitiesByDate: {
                merge(_: unknown, incoming: unknown) {
                    return incoming;
                }
            }
        }
    }
};

const client = new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: httpLink
});

const Apollo = ({ children }: { children: ReactNode }) => (
    <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default Apollo;
