import { compose } from 'lodash/fp';
import { DateTime } from 'luxon';
import { defaultFormValues } from './defaultFormValues';
import { show } from 'redux-modal';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';

import Ads from './components/Ads';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import Form, { FormValues } from './components/Form';
import InfoBox from './components/InfoBox';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { responsiveStyles } from './styles';
import SimpleAlertModal from 'src/modals/SimpleAlertModal';
import { Text } from 'src/SharedComponents';
import useAdBlockDetectedModal from 'src/hooks/useAdBlockDetectedModal';
import { useCreateEventMutation } from 'src/graphql/generated/artifacts';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const CreateEventScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isDarkMode = useIsDarkMode();
    const windowWidth = useWindowWidth();

    const [loading, setLoading] = useState<boolean>(false);
    const showModal = compose(dispatch, show);

    useEffect(() => window.scrollTo(0, 0), []);
    useAdBlockDetectedModal();

    const [createEvent] = useCreateEventMutation({
        onCompleted: data => {
            setLoading(false);
            navigate(`/${data?.createEvent?.id ?? ''}`);
        },
        onError: err => {
            setLoading(false);
            console.error(err);
            showModal('SimpleAlertModal', {
                header: 'Awww... 😢',
                text: 'Sorry, we had some trouble creating this event. Try refreshing the page and creating the event again.',
                isError: true
            });
        }
    });

    const onSubmit = useCallback(async (values: FormValues) => {
        setLoading(true);
        const variables = {
            name: values.name,
            description: values.description,
            timezone: values.timezone,
            usesWeekdays: values.usesWeekdays,
            startTime: DateTime.fromJSDate(values.startTime).toFormat('HH:mm'),
            endTime: DateTime.fromJSDate(values.endTime).toFormat('HH:mm'),
            dates: values.dates.map((date: Date) =>
                DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')
            )
        };
        await createEvent({ variables });
    }, []);

    const styles = responsiveStyles(windowWidth, isDarkMode);

    return (
        <>
            <SimpleAlertModal />
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.contentContainer}>
                        <div style={styles.formWrap}>
                            <div style={styles.formTitleWrap}>
                                <Text bold larger style={styles.headerText}>
                                    Create an Event
                                </Text>
                            </div>
                            <InfoBox />
                            <div style={styles.formFields}>
                                <Form
                                    loading={loading}
                                    initialValues={defaultFormValues}
                                    onSubmit={values => {
                                        void onSubmit(values);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={styles.adWrap}>
                            <Ads count={5} />
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

export default CreateEventScreen;
