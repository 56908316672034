import './styles/index.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import Apollo from './graphql/Apollo';
import Redux from './state/redux';

const wrappedApp = (
    <Apollo>
        <Redux>
            <App />
        </Redux>
    </Apollo>
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(wrappedApp);
