import { ThemeProvider } from '@mui/material';
import React, { useState } from 'react';

import CreateTempUser from './components/CreateTempUser';
import EnterEdit from './components/EnterEdit';
import InputSelect from './components/InputSelect';
import SelectTempUser from './components/SelectTempUser';
import { theme } from '../../styles';
import { useIsDarkMode } from 'src/hooks';

interface Props {
    eventId: string;
}

const AvailabilityInput = (props: Props) => {
    const [step, setStep] = useState<number>(0);
    const isDarkMode = useIsDarkMode();
    return (
        <ThemeProvider theme={theme(isDarkMode)}>
            <Content eventId={props.eventId} step={step} setStep={setStep} />
        </ThemeProvider>
    );
};

const Content = (props: {
    eventId: string;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const { eventId, step, setStep } = props;
    switch (step) {
        case 0:
            return <EnterEdit eventId={eventId} setStep={setStep} />;
        case 1:
            return <CreateTempUser eventId={eventId} setStep={setStep} />;
        case 2:
            return <SelectTempUser eventId={eventId} setStep={setStep} />;
        case 3:
            return <InputSelect eventId={eventId} setStep={setStep} />;
        default:
            return null;
    }
};

export default AvailabilityInput;
