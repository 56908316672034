import { compose } from 'lodash/fp';
import { CSSProperties, useState } from 'react';
import { show } from 'redux-modal';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';

import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { setUser } from 'src/state/reactiveVars';
import { updateEventCache } from './updateEventCache';
import { useCreateEventUserMutation } from 'src/graphql/generated/artifacts';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 600;
const SMALL_BREAKPOINT = 400;

interface Props {
    eventId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CreateTempUser = (props: Props) => {
    const { eventId, setStep } = props;
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    const _showModal = compose(dispatch, show);

    const [name, setName] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [createEventUser] = useCreateEventUserMutation();

    const onPress = async () => {
        setLoading(true);

        if (name.trim().length === 0) {
            setLoading(false);
            return setShowError(true);
        } else setShowError(false);

        try {
            const res = await createEventUser({
                variables: { name, eventId },
                update: (cache, result) => {
                    updateEventCache(cache, result, eventId);
                }
            });
            setUser(res?.data?.createEventUser ?? null);
            setStep(3);
        } catch (err) {
            console.error(err);
            _showModal('SimpleAlertModal', {
                header: 'Sorry 😢',
                text: 'We had an issue adding you to this event.',
                isError: true
            });
        }
    };

    return (
        <div style={styles.container}>
            <Text style={styles.text}>
                Enter your name in the field below, so the host knows who you
                are.
            </Text>
            <div style={styles.fieldContainer}>
                <TextField
                    type="name"
                    name="name"
                    value={name}
                    variant="standard"
                    onChange={e => setName(e.target.value)}
                    label="Name"
                    helperText="What's your name? ie. Suzy Bae"
                    style={styles.field}
                />
                <br />
                {showError && (
                    <Text
                        small
                        color={Colors.errorRed}
                        style={{ marginTop: 10 }}
                    >
                        Please enter a valid name!
                    </Text>
                )}
            </div>
            <div style={styles.buttonContainer}>
                <Button
                    label="Continue"
                    onPress={() => void onPress()}
                    style={styles.continueButtonStyle}
                    loading={loading}
                    disabled={loading}
                />
                {windowWidth < SMALL_BREAKPOINT && <br />}
                <Button
                    label="Back"
                    onPress={() => setStep(0)}
                    style={styles.backButtonStyle}
                />
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: 30,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
        textAlign: 'left'
    } as CSSProperties,
    fieldContainer: {
        width: '100%',
        textAlign: windowWidth > BREAKPOINT ? 'left' : 'center',
        padding: '20px 0 10px 0'
    } as CSSProperties,
    field: {
        width: '100%',
        maxWidth: 300
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    buttonContainer: {
        width: '100%',
        textAlign: windowWidth > BREAKPOINT ? 'left' : 'center',
        paddingTop: 20
    } as CSSProperties,
    continueButtonStyle: {
        fontFamily: 'Futura-Medium',
        height: 40,
        width: 140,
        padding: 0
    } as CSSProperties,
    backButtonStyle: {
        fontFamily: 'Futura-Medium',
        height: 40,
        width: 100,
        padding: 0,
        margin: windowWidth > SMALL_BREAKPOINT ? '0 0 0 30px' : '20px 0 0 0',
        background: Colors.secondary
    } as CSSProperties
});

export default CreateTempUser;
