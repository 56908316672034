import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment-timezone';
import styles from './styles.module.scss';

const timezones: string[] = moment.tz.names();

type Props = {
    selectedTimezone: string;
    setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>;
};

const TimezoneSelect = (props: Props) => {
    const { selectedTimezone, setSelectedTimezone } = props;
    return (
        <div className={styles['container']}>
            <Autocomplete
                options={timezones}
                className={styles['field']}
                value={selectedTimezone}
                disableClearable
                onChange={(_event, newValue: string) => {
                    setSelectedTimezone(newValue);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Timezone"
                        variant="standard"
                    />
                )}
            />
        </div>
    );
};

export default TimezoneSelect;
