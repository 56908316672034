import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { ReactNode } from 'react';
import { reducer as modal } from 'redux-modal';

const store = configureStore({
    reducer: { modal },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

const Redux = (props: { children: ReactNode }) => (
    <Provider store={store}>{props.children}</Provider>
);

export default Redux;
