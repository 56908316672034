import { CSSProperties } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';

import { Colors } from 'src/utils';
import { Text } from 'src/SharedComponents';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const Footer = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    return (
        <div style={styles.container}>
            <div style={styles.copyrightWrap}>
                <Text small color={isDarkMode ? Colors.white : Colors.darkgray}>
                    © Copyright {DateTime.now().toFormat('yyyy')} Vailable. All
                    rights reserved.
                </Text>
            </div>
            <div style={styles.linksWrap}>
                <Text small color={Colors.secondary}>
                    <Text
                        small
                        onPress={() => {
                            navigate(`/terms`);
                        }}
                        color={Colors.secondary}
                        style={{ cursor: 'pointer' }}
                        purpleHover
                    >
                        Terms of Use
                    </Text>{' '}
                    |{' '}
                    <Text
                        small
                        onPress={() => {
                            navigate(`/contact`);
                        }}
                        color={Colors.secondary}
                        style={{ cursor: 'pointer' }}
                        purpleHover
                    >
                        Contact
                    </Text>
                </Text>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        zIndex: 1,
        width: '100%',
        padding: windowWidth < BREAKPOINT ? '30px 30px 60px 30px' : 30,
        textAlign: windowWidth < BREAKPOINT ? 'center' : 'left',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    copyrightWrap: {
        display: 'inline-block',
        width: windowWidth < BREAKPOINT ? '100%' : 'auto'
    } as CSSProperties,
    linksWrap: {
        float: 'right',
        width: windowWidth < BREAKPOINT ? '100%' : 'auto',
        marginTop: windowWidth < BREAKPOINT ? 10 : 0
    } as CSSProperties
});

export default Footer;
