import { ApolloCache } from '@apollo/client';
import {
    AvailabilitiesByDateQuery,
    AvailabilitiesByDateDocument,
    AvailabilitiesByDateQueryVariables,
    UserProfileFragment
} from 'src/graphql/generated/artifacts';
import { uniqBy, orderBy } from 'lodash/fp';

export const createAvailabilitiesUpdateCache = (
    cache: ApolloCache<unknown>,
    eventId: string,
    user: UserProfileFragment,
    date: string,
    dateTimes: string[]
) => {
    /**
     * Update individual user cache
     */
    cache.updateQuery<
        AvailabilitiesByDateQuery,
        AvailabilitiesByDateQueryVariables
    >(
        {
            query: AvailabilitiesByDateDocument,
            variables: { eventId, userIds: [user.id], date }
        },
        data => {
            if (!data?.availabilitiesByDate) {
                return null;
            }
            return {
                ...data,
                availabilitiesByDate: orderBy(
                    'dateTime',
                    'asc',
                    uniqBy('dateTime', [
                        ...data.availabilitiesByDate,
                        ...dateTimes.map(dateTime => ({
                            dateTime,
                            availablePeople: [user]
                        }))
                    ])
                )
            };
        }
    );

    /**
     * Update aggregated users cache
     */
    cache.updateQuery<
        AvailabilitiesByDateQuery,
        AvailabilitiesByDateQueryVariables
    >(
        {
            query: AvailabilitiesByDateDocument,
            variables: { eventId, date }
        },
        data => {
            if (!data?.availabilitiesByDate) {
                return null;
            }

            const dateTimesToAdd = new Set(dateTimes);
            const newAvailabilities = data.availabilitiesByDate.map(
                availability => {
                    if (dateTimesToAdd.has(availability.dateTime)) {
                        dateTimesToAdd.delete(availability.dateTime);
                        return {
                            ...availability,
                            availablePeople: orderBy('name', 'asc', [
                                ...availability.availablePeople,
                                user
                            ])
                        };
                    }
                    return availability;
                }
            );

            dateTimesToAdd.forEach(dateTime => {
                newAvailabilities.push({
                    dateTime,
                    availablePeople: [user]
                });
            });

            return {
                ...data,
                availabilitiesByDate: orderBy(
                    'dateTime',
                    'asc',
                    newAvailabilities
                )
            };
        }
    );
};

export const removeAvailabilitiesUpdateCache = (
    cache: ApolloCache<unknown>,
    eventId: string,
    userId: string,
    date: string,
    dateTimes: string[]
) => {
    /**
     * Update individual user cache
     */
    cache.updateQuery<
        AvailabilitiesByDateQuery,
        AvailabilitiesByDateQueryVariables
    >(
        {
            query: AvailabilitiesByDateDocument,
            variables: { eventId, userIds: [userId], date }
        },
        data => {
            if (!data?.availabilitiesByDate) {
                return null;
            }
            const dateTimesToDelete = new Set(dateTimes);
            return {
                ...data,
                availabilitiesByDate: data.availabilitiesByDate.filter(
                    availability =>
                        !dateTimesToDelete.has(availability.dateTime)
                )
            };
        }
    );

    /**
     * Update aggregated users cache
     */
    cache.updateQuery<
        AvailabilitiesByDateQuery,
        AvailabilitiesByDateQueryVariables
    >(
        {
            query: AvailabilitiesByDateDocument,
            variables: { eventId, date }
        },
        data => {
            if (!data?.availabilitiesByDate) {
                return null;
            }

            const dateTimesToRemove = new Set(dateTimes);
            const newAvailabilities = data.availabilitiesByDate
                .map(availability => {
                    if (dateTimesToRemove.has(availability.dateTime)) {
                        return {
                            ...availability,
                            availablePeople:
                                availability.availablePeople.filter(
                                    u => u.id !== userId
                                )
                        };
                    }
                    return availability;
                })
                .filter(
                    availability => availability.availablePeople.length > 0
                );

            return {
                ...data,
                availabilitiesByDate: newAvailabilities
            };
        }
    );
};
