import './styles.css';

import {
    CSSProperties,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useState
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { Colors } from 'src/utils';

import { Text } from '../Text';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

interface MenuItem {
    icon: JSX.Element;
    text: string;

    // when menu item is clicked,
    // the pop out menu will stay
    // open
    preventCloseOnClick?: boolean;

    onClick: () => void;
}

interface Props {
    // style overrides
    className?: string;
    style?: CSSProperties;

    // space between left border of
    // PopOutMenu and the menu item
    // icons
    menuItemLeftPadding?: number;

    // colors
    backgroundColor?: string;
    borderColor?: string;
    titleTextColor?: string;
    menuItemTextColor?: string;

    // title box
    title?: string | JSX.Element;

    // menu items
    menuItems: MenuItem[];

    // content container styles
    contentContainerStyles?: CSSProperties;

    // open-close animations
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    expandTopRight?: boolean;
    expandTopLeft?: boolean;
    expandTopCenter?: boolean;

    // functions
    onClose?: () => void;
}

export const PopOutMenu: FunctionComponent<Props> = props => {
    const {
        style,
        menuItemLeftPadding,
        backgroundColor,
        borderColor,
        setIsOpen,
        isOpen,
        title,
        menuItems,
        contentContainerStyles,
        expandTopLeft,
        expandTopCenter,
        onClose
    } = props;

    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);

    let animation = 'top_right';

    if (expandTopLeft) {
        animation = 'top_left';
    }
    if (expandTopCenter) {
        animation = 'top_center';
    }

    return (
        <>
            <div
                style={{
                    ...styles.wrapper,
                    ...style,
                    ...(backgroundColor && { backgroundColor }),
                    ...(borderColor && { borderColor })
                }}
            >
                <CSSTransition
                    in={isOpen}
                    timeout={300}
                    classNames={animation}
                    unmountOnExit
                >
                    <div style={styles.container}>
                        <div style={styles.headerContainer}>{title}</div>
                        <div
                            className="green_scroller"
                            style={contentContainerStyles}
                        >
                            {menuItems.map((item: MenuItem, i: number) => (
                                <MenuOption
                                    item={item}
                                    setIsOpen={setIsOpen}
                                    menuItemLeftPadding={
                                        menuItemLeftPadding
                                            ? menuItemLeftPadding
                                            : 30
                                    }
                                    onClose={onClose}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </CSSTransition>
            </div>
            {isOpen && (
                <div
                    onClick={() => {
                        setIsOpen(false);
                        if (onClose) onClose();
                    }}
                    style={styles.closeListener}
                />
            )}
        </>
    );
};

const MenuOption = (props: {
    item: MenuItem;
    menuItemLeftPadding: number;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onClose?: () => void;
}) => {
    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);

    const [hover, setHover] = useState<boolean>(false);
    const { item, menuItemLeftPadding, setIsOpen, onClose } = props;
    return (
        <div
            style={{
                ...styles.menuItemWrap,
                ...(hover && { ...styles.menuItemWrapHover })
            }}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                item.onClick();
                if (!item.preventCloseOnClick) {
                    setIsOpen(false);
                    if (onClose) onClose();
                }
            }}
        >
            <div
                style={{
                    ...styles.menuItemIconWrap,
                    marginLeft: menuItemLeftPadding
                }}
            >
                {item.icon}
            </div>
            <div style={styles.menuTextWrap}>
                <Text style={styles.defaultTextColor}>{item.text}</Text>
            </div>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    closeListener: {
        position: 'fixed',
        zIndex: 1,
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0
    } as CSSProperties,
    wrapper: {
        position: 'absolute',
        zIndex: 2,
        right: 0,
        width: 240
    } as CSSProperties,
    container: {
        width: '100%',
        overflow: 'hidden',
        background: isDarkMode ? Colors.midnight : Colors.white,
        border: `1px solid ${isDarkMode ? Colors.dusk : Colors.outlinegray}`,
        borderRadius: 10
    } as CSSProperties,
    headerContainer: {
        width: '100%',
        borderBottom: `1px solid ${
            isDarkMode ? Colors.dusk : Colors.outlinegray
        }`,
        textAlign: 'center',
        padding: '18px 15px'
    } as CSSProperties,
    menuItemWrap: {
        width: '100%',
        padding: '20px 0 20px 0',
        textAlign: 'left',
        cursor: 'pointer',
        position: 'relative'
    } as CSSProperties,
    menuItemWrapHover: {
        backgroundColor: isDarkMode ? Colors.darknight : '#f5f8fa'
    } as CSSProperties,
    menuItemIconWrap: {
        width: 20,
        height: 20,
        marginRight: 20,
        display: 'inline-block',
        verticalAlign: 'middle'
    } as CSSProperties,
    menuTextWrap: {
        margin: 0,
        display: 'inline-block',
        verticalAlign: 'middle'
    } as CSSProperties,
    defaultTextColor: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties
});
