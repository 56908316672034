import { DateTime } from 'luxon';
import { FormValues } from './components/Form';

export const defaultFormValues: FormValues = {
    name: '',
    description: '',
    startTime: DateTime.fromFormat('09:00', 'HH:mm').toJSDate(),
    endTime: DateTime.fromFormat('21:00', 'HH:mm').toJSDate(),
    timezone: DateTime.local().zoneName,
    dates: [new Date()],
    usesWeekdays: false
};
