import { CSSProperties, useEffect } from 'react';
import { useWindowWidth } from '@react-hook/window-size';

import { Colors } from 'src/utils';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

import BetterInputsScreenshot from 'src/assets/screenshots/better_inputs_example.png';
import KickOutScreenshot from 'src/assets/screenshots/kick_out_example.png';

const BREAKPOINT = 850;

const UpdatesScreen = () => {
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            Some{' '}
                            <span style={{ color: Colors.secondary }}>
                                updates.
                            </span>
                        </Text>
                        <br />
                        <Text larger style={styles.mainSubHeaderText}>
                            The latest features{' '}
                            <span role="img" aria-label="heart-eyes">
                                😍
                            </span>
                        </Text>
                        <div style={styles.contentWrap}>
                            <Text
                                larger
                                bolder
                                style={{
                                    ...styles.sectionHeaderText,
                                    marginTop: 0
                                }}
                            >
                                <span style={{ color: Colors.primary }}>v</span>
                                2.1.0{' '}
                                <span role="img" aria-label="chainsaw">
                                    🪚
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Hey everyone! I've finally finished the major
                                infrastructure revamp, which means we are
                                finally getting more features!
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                First and foremost, we have added the ability
                                for you to kick out users from an event. This is
                                super useful for removing duplicate users, or
                                simply removing someone who is no longer
                                relevant to the event. To kick someone out, just
                                click on the kick button to the right of their
                                name.
                            </Text>
                            <br />
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={KickOutScreenshot}
                                    alt="kick-out-example"
                                    style={styles.screenshotSmall}
                                />
                            </div>
                            <br />
                            <Text style={styles.sectionText}>
                                In addition, you may notice some of our input
                                fields are much better. For example, the user
                                filter and timezone inputs now allow you to type
                                and autocomplete instead of the annoying scroll
                                and select we had before.
                            </Text>
                            <br />
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={BetterInputsScreenshot}
                                    alt="better-inputs-example"
                                    style={styles.screenshotSmall}
                                />
                            </div>
                            <br />
                            <Text style={styles.sectionText}>
                                I hope you all enjoy the update! Note that we
                                have even cooler features coming soon.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>- Jason</Text>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 650,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    contentWrap: {
        width: '100%',
        textAlign: 'left',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 650,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack,
        marginTop: 40
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        marginTop: 10
    } as CSSProperties,
    screenshotWrap: {
        padding: '40px 0 40px 0',
        width: '100%',
        textAlign: 'center'
    } as CSSProperties,
    screenshotLarge: {
        display: 'inline-block',
        borderRadius: 20,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        maxWidth: '100%'
    } as CSSProperties,
    screenshotSmall: {
        display: 'inline-block',
        borderRadius: 20,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        maxWidth: windowWidth < BREAKPOINT ? '100%' : 300
    } as CSSProperties
});

export default UpdatesScreen;
