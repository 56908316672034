import { makeVar } from '@apollo/client';
import { UserProfileFragment } from 'src/graphql/generated/artifacts';

/**
 * Dark Mode
 */
export const isDarkMode = makeVar<boolean>(false);
export const setIsDarkMode = (isDarkModeToSet: boolean) => {
    localStorage.setItem('isDarkMode', isDarkModeToSet ? 'true' : 'false');
    const html = document.querySelector('html');
    if (html) {
        html.dataset.theme = isDarkModeToSet ? 'theme-dark' : 'theme-light';
    }
    isDarkMode(isDarkModeToSet);
};

/**
 * User
 */
export const user = makeVar<UserProfileFragment | null>(null);
export const setUser = (profile: UserProfileFragment | null) => {
    user(profile);
};
