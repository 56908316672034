import 'react-infinite-calendar/styles.css';

import DateFnsUtils from '@date-io/date-fns';
import { DateTime } from 'luxon';
import { FormHelperText, MuiThemeProvider, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useWindowWidth } from '@react-hook/window-size';
import { Form as FormikForm, Formik } from 'formik';
import InfiniteCalendar, {
    Calendar,
    defaultMultipleDateInterpolation,
    withMultipleDates
} from 'react-infinite-calendar';
import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';

import { responsiveStyles, theme } from '../styles';
import { createEventValidationSchema } from './FormValidator';
import MobileAd from './MobileAd';
import WeekdayPicker from './WeekdayPicker';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

export interface FormValues {
    name: string;
    description: string;
    startTime: Date;
    endTime: Date;
    timezone: string;
    dates: Date[];
    usesWeekdays: boolean;
}

interface Props {
    loading: boolean;
    initialValues: FormValues;
    onSubmit: (values: FormValues) => void;
}

const Form = (props: Props) => {
    const { loading, initialValues, onSubmit } = props;
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    return (
        <Formik
            validateOnChange={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={createEventValidationSchema}
            onSubmit={onSubmit}
        >
            {({ values, errors, handleChange, setFieldValue }) => (
                <FormikForm>
                    <MuiThemeProvider theme={theme(isDarkMode)}>
                        <TextField
                            type="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            label="Event Name"
                            helperText="What is your event called? (ie. Pre-launch Meeting)"
                            style={styles.field}
                        />
                        <br />
                        <FormError message={errors.name} />

                        <TextField
                            type="description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            label="Event Description"
                            helperText="Optional: Provide some brief info about your event. (ie. location, things to bring)"
                            style={styles.field}
                            multiline
                        />
                        <br />

                        <ToggleButtonGroup
                            value={values.usesWeekdays}
                            exclusive
                            onChange={(_, usesWeekdays) => {
                                if (usesWeekdays !== null) {
                                    setFieldValue('dates', [new Date()]);
                                    setFieldValue('usesWeekdays', usesWeekdays);
                                }
                            }}
                            style={styles.dateDaysToggle}
                        >
                            <ToggleButton value={false}>Dates</ToggleButton>
                            <ToggleButton value={true}>
                                Days of Week
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <br />

                        {values.usesWeekdays ? (
                            <>
                                <div style={styles.weekdayPickerWrap}>
                                    <WeekdayPicker
                                        selected={values.dates}
                                        onSelect={(selectedDate: Date) =>
                                            handleDateSelect(
                                                selectedDate,
                                                values.dates,
                                                setFieldValue
                                            )
                                        }
                                    />
                                    <FormHelperText
                                        style={{ width: 300, marginTop: 20 }}
                                    >
                                        Select the possible days of the week for
                                        your event. Make sure to use your
                                        current local timezone!
                                    </FormHelperText>
                                </div>
                                <br />
                                <FormError message={errors.dates?.toString()} />
                            </>
                        ) : (
                            <>
                                <div style={styles.infiniteCalendarWrap}>
                                    <InfiniteCalendar
                                        Component={withMultipleDates(Calendar)}
                                        interpolateSelection={
                                            defaultMultipleDateInterpolation as unknown as
                                                | ((
                                                      date: Date,
                                                      selected: Date[]
                                                  ) => Date[])
                                                | undefined
                                        }
                                        selected={values.dates}
                                        onSelect={(selectedDate: Date) =>
                                            handleDateSelect(
                                                selectedDate,
                                                values.dates,
                                                setFieldValue
                                            )
                                        }
                                        autoFocus={false}
                                        width={300}
                                        height={300}
                                        minDate={new Date()}
                                        theme={styles.infiniteCalendar}
                                    />
                                    <FormHelperText
                                        style={{ width: 300, marginTop: 5 }}
                                    >
                                        Select the possible dates for your
                                        event. Make sure to use your current
                                        local timezone!
                                    </FormHelperText>
                                </div>
                                <br />
                                <FormError message={errors.dates?.toString()} />
                            </>
                        )}

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Earliest time of your event."
                                value={values.startTime}
                                onChange={startTime =>
                                    setFieldValue('startTime', startTime)
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change time'
                                }}
                                minutesStep={15}
                                style={styles.field}
                            />
                            <br />
                            <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Latest time of your event."
                                value={values.endTime}
                                onChange={endTime =>
                                    setFieldValue('endTime', endTime)
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change time'
                                }}
                                minutesStep={15}
                                style={styles.field}
                            />
                        </MuiPickersUtilsProvider>
                        <br />

                        <Button
                            submit
                            label="Create Event"
                            style={styles.buttonStyle}
                            hoverStyle={styles.buttonHoverStyle}
                            loading={loading}
                            disabled={loading}
                        />

                        <MobileAd />
                    </MuiThemeProvider>
                </FormikForm>
            )}
        </Formik>
    );
};

/**
 * InfiniteCalendar doesn't tell us whether selectedDate is
 * being selected or unselected. We figure this out by filtering
 * out the selectedDate from our dates. If the length changed,
 * then it wasn't in the original dates so we add it. Otherwise,
 * it gets removed.
 */
const handleDateSelect = (
    selectedDate: Date,
    dates: Date[],
    setFieldValue: (
        field: string,
        value: Date[],
        shouldValidate?: boolean | undefined
    ) => void
) => {
    const newDates = dates.filter(
        (date: Date) =>
            DateTime.fromJSDate(selectedDate).toFormat('yyyy-LL-dd') !==
            DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')
    );
    if (newDates.length === dates.length) {
        newDates.push(selectedDate);
    }
    setFieldValue('dates', newDates);
};

const FormError = (props: { message: string | undefined }) => {
    if (!props.message) {
        return null;
    }
    return (
        <>
            <Text color={Colors.errorRed} style={{ marginTop: 30 }}>
                {props.message}
            </Text>
            <br />
        </>
    );
};

export default Form;
