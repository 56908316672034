import * as yup from 'yup';

export const createEventValidationSchema = yup.object({
    name: yup.string().required("What's your event called?"),
    description: yup.string().optional(),
    startTime: yup.date().required(),
    endTime: yup.date().required(),
    timezone: yup.string().required(),
    dates: yup
        .array()
        .of(yup.date())
        .required('Select at least one possible date or day of the week.'),
    usesWeekdays: yup.boolean().required()
});
