import { CSSProperties } from 'react';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';

interface Props {
    description: string;
}

const EventDescription = (props: Props) => {
    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);
    return (
        <div style={styles.container}>
            <Text style={styles.text}>{props.description}</Text>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: '20px 30px 20px 30px',
        textAlign: 'left',
        background: isDarkMode ? Colors.night : Colors.backgroundgray,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties
});

export default EventDescription;
