import { CSSProperties } from 'react';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';
interface Props {
    title: string;
}

const EventTitle = (props: Props) => {
    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);
    return (
        <div style={styles.container}>
            <Text bold larger style={styles.headerText}>
                {props.title}
            </Text>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: '20px 30px 20px 30px',
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    headerText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties
});

export default EventTitle;
