import { useReactiveVar } from '@apollo/client';
import { UserProfileFragment } from 'src/graphql/generated/artifacts';
import { user } from 'src/state/reactiveVars';

export const useUser = (): UserProfileFragment | null => {
    const currentUser = useReactiveVar(user);
    return currentUser;
};

export default useUser;
