import { CSSProperties, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import gradstop from 'gradstop';
import { useWindowWidth } from '@react-hook/window-size';

import AvailablePeopleBox from './components/AvailablePeopleBox';
import { Colors, getPossibleDates, getPossibleTimes } from 'src/utils';
import TimeDisplayBar from './components/TimeDisplayBar';
import TimeLabels from 'src/components/TimeLabels';
import UserFilterSelect from './components/UserFilterSelect';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';
import { UserProfileFragment } from 'src/graphql/generated/artifacts';
import { useIsDarkMode } from 'src/hooks';

const BREAKPOINT = 900;

export interface HoveredTimeBlock {
    startTime: DateTime;
    endTime: DateTime;
    date: DateTime;
    availableUsers: UserProfileFragment[];
}

interface Props {
    eventId: string;
    selectedTimezone: string;
}

const EventTimeDisplay = (props: Props) => {
    const { eventId, selectedTimezone } = props;
    const windowWidth = useWindowWidth();

    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth);

    const [possibleDates, setPossibleDates] = useState<DateTime[]>([]);
    const [possibleTimes, setPossibleTimes] = useState<DateTime[]>([]);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [colorGradient, setColorGradient] = useState<string[]>([]);

    const [hoveredTimeBlock, setHoveredTimeBlock] =
        useState<HoveredTimeBlock | null>(null);

    const { data, loading, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    useEffect(() => {
        if (data?.eventById?.dates && data?.eventById?.timezone) {
            const dates = getPossibleDates(
                data.eventById.dates,
                data.eventById.timezone
            );
            setPossibleDates(dates);
        }
    }, [data]);

    useEffect(() => {
        if (
            data?.eventById?.startTime &&
            data?.eventById?.endTime &&
            data?.eventById?.timezone
        ) {
            const times = getPossibleTimes(
                data.eventById.startTime,
                data.eventById.endTime,
                data.eventById.timezone
            );
            setPossibleTimes(times);
        }
    }, [data]);

    useEffect(() => {
        const userCount = selectedUserIds.length;
        const stopCount = userCount === 0 ? 2 : userCount + 1;

        const gradient = gradstop({
            stops: stopCount,
            inputFormat: 'hex',
            colorArray: ['#f2e3ff', '#700eb4']
        });

        gradient[0] = isDarkMode ? Colors.night : Colors.white;
        setColorGradient(gradient);
    }, [selectedUserIds, isDarkMode]);

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            <div style={styles.timeDisplayBox}>
                <div style={styles.timeBarsLabel}>
                    <TimeLabels
                        possibleTimes={possibleTimes}
                        selectedTimezone={selectedTimezone}
                    />
                </div>
                <div style={styles.timeBarsContainer}>
                    {possibleDates.map((date: DateTime, i: number) => (
                        <TimeDisplayBar
                            key={i}
                            eventId={eventId}
                            usesWeekdays={
                                data?.eventById?.usesWeekdays ?? false
                            }
                            date={date}
                            possibleTimes={possibleTimes}
                            selectedUserIds={selectedUserIds}
                            colorGradient={colorGradient}
                            setHoveredTimeBlock={setHoveredTimeBlock}
                        />
                    ))}
                </div>
                <UserFilterSelect
                    eventId={eventId}
                    selectedUserIds={selectedUserIds}
                    setSelectedUserIds={setSelectedUserIds}
                />
            </div>
            <AvailablePeopleBox
                eventId={eventId}
                hoveredTimeBlock={hoveredTimeBlock}
                selectedUserIds={selectedUserIds}
                selectedTimezone={selectedTimezone}
            />
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    container: {
        width: '100%',
        textAlign: 'center',
        overflow: 'none'
    } as CSSProperties,
    timeDisplayBox: {
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'center',
        width: windowWidth > BREAKPOINT ? 400 : '100%',
        minHeight: windowWidth > BREAKPOINT ? 500 : 0
    } as CSSProperties,
    timeBarsLabel: {
        width: 50,
        verticalAlign: 'top',
        paddingTop: 37,
        display: 'inline-block'
    } as CSSProperties,
    timeBarsContainer: {
        width: windowWidth > BREAKPOINT ? 350 : 230,
        whiteSpace: 'nowrap',
        overflowX: 'scroll',
        display: 'inline-block',
        textAlign: 'left',
        verticalAlign: 'top'
    } as CSSProperties
});

export default EventTimeDisplay;
