import { useWindowWidth } from '@react-hook/window-size';
import { compose } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { show } from 'redux-modal';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import EventNotFoundModal from 'src/modals/EventNotFoundModal';
import SimpleAlertModal from 'src/modals/SimpleAlertModal';
import useAdBlockDetectedModal from 'src/hooks/useAdBlockDetectedModal';

import Ads from '../CreateEvent/components/Ads';
import Availability from './components/Availability';
import AvailabilityInput from './components/AvailabilityInput';
import ConfirmModal from 'src/modals/ConfirmModal';
import CopyLink from './components/CopyLink';
import EventDescription from './components/EventDescription';
import EventTitle from './components/EventTitle';
import Loader from './components/Loader';
import Participants from './components/Participants';
import { responsiveStyles } from './styles';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';
import { useIsDarkMode } from 'src/hooks';

const EventScreen = () => {
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const { eventId } = useParams();
    const _showModal = compose(dispatch, show);

    const isDarkMode = useIsDarkMode();
    useAdBlockDetectedModal();

    const { loading, error, data } = useEventByIdQuery({
        variables: { id: eventId ?? '' }
    });

    if (!loading && (error || !data?.eventById)) {
        _showModal('EventNotFoundModal');
    }
    const styles = responsiveStyles(windowWidth, isDarkMode);

    if (!eventId) {
        return null;
    }

    return (
        <>
            <ConfirmModal />
            <SimpleAlertModal />
            <EventNotFoundModal />
            <div style={styles.pageContainer}>
                <NavBar />
                {loading && <Loader />}
                {data && (
                    <FadeIn>
                        <div style={styles.contentContainer}>
                            <div style={styles.eventWrap}>
                                <EventTitle
                                    title={data?.eventById?.name ?? ''}
                                />
                                {data?.eventById?.description && (
                                    <EventDescription
                                        description={
                                            data?.eventById?.description ?? ''
                                        }
                                    />
                                )}
                                <CopyLink eventId={eventId} />
                                <AvailabilityInput eventId={eventId} />
                                <Availability eventId={eventId} />
                                <Participants eventId={eventId} />
                            </div>
                            <div style={styles.adWrap}>
                                <Ads count={4} />
                            </div>
                        </div>
                    </FadeIn>
                )}
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

export default EventScreen;
