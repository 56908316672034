import { CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';

const AlertBanner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    if (location.pathname === '/updates') {
        localStorage.setItem('showAlertBanner', 'false');
        return null;
    }

    return (
        <div style={styles.container}>
            <Text color={Colors.white}>
                <span aria-label="wave" role="img">
                    👋
                </span>{' '}
                hey! you can kick users out of events now. read more{' '}
                <Text
                    onPress={() => {
                        localStorage.setItem('showAlertBanner', 'false');
                        navigate(`/updates`);
                    }}
                    className="hover-darken"
                    color={Colors.white}
                    style={{ cursor: 'pointer' }}
                    underline
                    bold
                >
                    here
                </Text>
            </Text>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        textAlign: 'center',
        padding: '10px 30px 10px 30px',
        position: 'relative',
        background: Colors.primary,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        zIndex: 1
    } as CSSProperties
};

export default AlertBanner;
