import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import AdSense from 'react-adsense';

const BREAKPOINT = 1000;

const MobileAd = () => {
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    if (process.env.REACT_APP_ENV !== 'production') {
        return null;
    }

    return (
        <div style={styles.container}>
            <AdSense.Google
                client="ca-pub-5147835488009805"
                slot="7323503129"
                style={styles.adsense}
                format=""
            />
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    container: {
        width: '100%',
        paddingTop: 60,
        display: windowWidth > BREAKPOINT ? 'none' : 'inline-block',
        textAlign: 'center'
    } as CSSProperties,
    adsense: {
        width: '100%',
        height: 200,
        display: 'inline-block'
    } as CSSProperties
});

export default MobileAd;
