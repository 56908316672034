import { CSSProperties } from 'react';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode, useUser } from 'src/hooks';

const Header = () => {
    const isDarkMode = useIsDarkMode();
    const user = useUser();
    const styles = coloredStyles(isDarkMode);
    return (
        <div style={styles.headerContainer}>
            <Text large bold style={styles.text}>
                {user?.name}'s Availability
            </Text>
            <br />
            <Text style={{ ...styles.text, marginTop: 10 }}>
                Click and drag to enter your available times for the event. No
                need to press submit/save - availabilities are updated in our
                database in real time!{' '}
                <span role="img" aria-label="smile">
                    😊
                </span>
            </Text>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    headerContainer: {
        width: '100%',
        maxWidth: 450,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties
});

export default Header;
