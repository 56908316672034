import 'antd/lib/modal/style';

import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { connectModal } from 'redux-modal';
import Owl from 'src/assets/img/owl.png';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const EventNotFoundModal = () => {
    const navigate = useNavigate();
    const isDarkMode = useIsDarkMode();
    return (
        <Modal
            visible={true}
            centered
            footer={null}
            width={600}
            className={
                isDarkMode ? 'vailable_antd_modal_dark' : 'vailable_antd_modal'
            }
            closable={false}
        >
            <div>
                <Text
                    larger
                    bolder
                    color={isDarkMode ? Colors.white : Colors.offBlack}
                >
                    Uh <span style={{ color: Colors.secondary }}>oh.</span>{' '}
                    <span role="img" aria-label="yikes">
                        😬
                    </span>
                </Text>
                <br />
                <Text
                    style={{
                        color: isDarkMode ? Colors.white : Colors.darkgray,
                        marginTop: 10
                    }}
                >
                    We couldn't find the event you were looking for. Don't worry
                    though! Click on Vaila the owl. He'll take you back home.
                </Text>
                <br />
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <img
                        className="hover-darken"
                        src={Owl}
                        onClick={() => navigate(`/`)}
                        alt=""
                        style={{
                            display: 'inline-block',
                            cursor: 'pointer',
                            maxWidth: 140,
                            marginTop: 30
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default connectModal({ name: 'EventNotFoundModal' })(EventNotFoundModal);
