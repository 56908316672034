import { ThemeProvider } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { DateTime } from 'luxon';
import TimezoneSelect from 'src/components/TimezoneSelect';

import EventTimeDisplay from './components/EventTimeDisplay';
import Header from './components/Header';
import MobileAd from '../../../CreateEvent/components/MobileAd';
import { theme } from '../../styles';
import { useIsDarkMode } from 'src/hooks';

interface Props {
    eventId: string;
}

const Availability = (props: Props) => {
    const { eventId } = props;
    const [selectedTimezone, setSelectedTimezone] = useState<string>(
        DateTime.local().zoneName
    );
    const isDarkMode = useIsDarkMode();

    return (
        <ThemeProvider theme={theme(isDarkMode)}>
            <div style={styles.container}>
                <Header />
                <TimezoneSelect
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                />
                <EventTimeDisplay
                    eventId={eventId}
                    selectedTimezone={selectedTimezone}
                />
                <MobileAd />
            </div>
        </ThemeProvider>
    );
};

const styles = {
    container: {
        width: '100%',
        padding: '30px 30px 60px 30px',
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
        textAlign: 'center'
    } as CSSProperties
};

export default Availability;
