import { createTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { Colors } from 'src/utils';

const BREAKPOINT = 1150;

export const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    contentContainer: {
        zIndex: 1,
        display: 'inline-block',
        position: 'relative',
        margin: '120px 0 60px 0',
        width: windowWidth > 1200 ? 1200 : 'calc(100% - 20px)',
        background: isDarkMode ? Colors.midnight : Colors.white,
        borderRadius: 25,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    eventWrap: {
        width: windowWidth > BREAKPOINT ? '75%' : '100%',
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'left',
        borderRight:
            windowWidth > BREAKPOINT
                ? '1px solid rgba(112, 112, 112, 0.2)'
                : 'none'
    } as CSSProperties,
    adWrap: {
        width: 'calc(25% - 1px)',
        height: '100%',
        display: windowWidth > BREAKPOINT ? 'inline-block' : 'none',
        verticalAlign: 'top',
        textAlign: 'left',
        padding: '20px 30px 20px 30px',
        overflow: 'hidden'
    } as CSSProperties
});

export const theme = (isDarkMode: boolean) =>
    createTheme({
        palette: {
            ...(isDarkMode && { mode: 'dark' }),
            primary: { main: Colors.primary, contrastText: '#fff' }
        },
        typography: { fontFamily: 'Futura-Medium' }
    });
