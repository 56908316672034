import { ApolloCache, FetchResult } from '@apollo/client';
import {
    CreateEventUserMutation,
    EventByIdQuery
} from 'src/graphql/generated/artifacts';

export const updateEventCache = (
    cache: ApolloCache<unknown>,
    result: Omit<FetchResult<CreateEventUserMutation>, 'context'>,
    eventId: string
) => {
    cache.modify({
        id: cache.identify({ __typename: 'Event', id: eventId }),
        fields: {
            participants(
                cachedParticipants: NonNullable<
                    EventByIdQuery['eventById']
                >['participants']
            ) {
                const newEventUser = result?.data?.createEventUser ?? null;
                return newEventUser
                    ? [...cachedParticipants, newEventUser]
                    : cachedParticipants;
            }
        }
    });
};
