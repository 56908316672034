import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { keyBy } from 'lodash/fp';

import styles from './styles.module.scss';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';

interface Props {
    eventId: string;
    selectedUserIds: string[];
    setSelectedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const UserFilterSelect = (props: Props) => {
    const { eventId, selectedUserIds, setSelectedUserIds } = props;
    const { data, loading, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    const participants = useMemo(
        () => data?.eventById?.participants ?? [],
        [data?.eventById?.participants]
    );

    const participantsIdMap = useMemo(
        () => keyBy('id', participants),
        [participants]
    );

    useEffect(() => {
        if (participants.length) {
            setSelectedUserIds(participants.map(u => u.id));
        }
    }, [participants]);

    if (loading || error || participants.length === 0) {
        return null;
    }

    return (
        <div className={styles['container']}>
            <Autocomplete
                multiple
                size="small"
                className={styles['multi-select']}
                options={participants.map(p => p.id)}
                getOptionLabel={id => participantsIdMap[id]?.name}
                value={selectedUserIds}
                onChange={(_event, newValue: string[]) => {
                    setSelectedUserIds(newValue);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Choose whose availabilities you want to see."
                    />
                )}
            />
        </div>
    );
};

export default UserFilterSelect;
