import React, { CSSProperties } from 'react';
import AdSense from 'react-adsense';
import { Text } from 'src/SharedComponents';
import { propOr } from 'lodash/fp';

interface Props {
    count: number;
}

const Ads = (props: Props) => {
    if (process.env.REACT_APP_ENV !== 'production') {
        return null;
    }
    const adArray = Array.from(
        { length: propOr(0, 'count', props) },
        (_, i) => i
    ).map((_, i: number) => (
        <AdSense.Google
            key={i}
            client="ca-pub-5147835488009805"
            slot="7323503129"
            style={styles.adsense}
            format=""
        />
    ));

    return (
        <>
            <Text small>Google Ads</Text>
            {adArray}
        </>
    );
};

const styles = {
    adsense: {
        width: '100%',
        height: 200,
        display: 'inline-block',
        margin: '20px 0 50px 0'
    } as CSSProperties
};

export default Ads;
