import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { getPossibleDates, getPossibleTimes } from 'src/utils';
import TimeLabels from 'src/components/TimeLabels';
import TimeSelectBar from './TimeSelectBar';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';

const BREAKPOINT = 800;

interface Props {
    eventId: string;
    selectedTimezone: string;
}

const TimeSelectInputs = (props: Props) => {
    const { eventId, selectedTimezone } = props;
    const windowWidth = useWindowWidth();
    const styles = responsiveStyles(windowWidth);

    const [possibleDates, setPossibleDates] = useState<DateTime[]>([]);
    const [possibleTimes, setPossibleTimes] = useState<DateTime[]>([]);

    const { data, loading, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    useEffect(() => {
        if (data?.eventById?.dates && data?.eventById?.timezone) {
            const dates = getPossibleDates(
                data.eventById.dates,
                data.eventById.timezone
            );
            setPossibleDates(dates);
        }
    }, [data]);

    useEffect(() => {
        if (
            data?.eventById?.startTime &&
            data?.eventById?.endTime &&
            data?.eventById?.timezone
        ) {
            const times = getPossibleTimes(
                data.eventById.startTime,
                data.eventById.endTime,
                data.eventById.timezone
            );
            setPossibleTimes(times);
        }
    }, [data]);

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            <div style={styles.contentContainer}>
                <div style={styles.timeBarsLabel}>
                    <TimeLabels
                        possibleTimes={possibleTimes}
                        selectedTimezone={selectedTimezone}
                    />
                </div>
                <div style={styles.timeBarsContainer}>
                    {possibleDates.map((date: DateTime, i: number) => (
                        <TimeSelectBar
                            key={i}
                            eventId={eventId}
                            date={date}
                            possibleTimes={possibleTimes}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number) => ({
    container: {
        width: '100%',
        textAlign: 'center',
        overflow: 'none'
    } as CSSProperties,
    contentContainer: {
        display: 'inline-block',
        verticalAlign: 'top',
        textAlign: 'center',
        width: windowWidth > BREAKPOINT ? 400 : '100%'
    } as CSSProperties,
    timeBarsLabel: {
        width: 50,
        verticalAlign: 'top',
        paddingTop: 37,
        display: 'inline-block'
    } as CSSProperties,
    timeBarsContainer: {
        width: windowWidth > BREAKPOINT ? 350 : 230,
        whiteSpace: 'nowrap',
        overflowX: 'scroll',
        display: 'inline-block',
        textAlign: 'left',
        verticalAlign: 'top'
    } as CSSProperties
});

export default TimeSelectInputs;
