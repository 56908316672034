import { withStyles } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import { propOr } from 'lodash/fp';
import { CSSProperties, FunctionComponent } from 'react';
import { ClipLoader } from 'react-spinners';
import { Colors } from 'src/utils';

interface Props {
    onPress?: () => void;
    variant?: 'text' | 'outlined' | 'contained';
    style?: CSSProperties;
    hoverStyle?: CSSProperties;
    label: string;
    loading?: boolean;
    disabled?: boolean;
    submit?: boolean;
}

export const Button: FunctionComponent<Props> = props => {
    const { onPress, label, variant, loading, disabled, submit } = props;
    const ButtonWithStyles = StyledButton(
        propOr({}, 'style', props),
        propOr({}, 'hoverStyle', props),
        propOr(false, 'disabled', props)
    );
    return (
        <ButtonWithStyles
            type={submit ? 'submit' : undefined}
            disabled={disabled}
            variant={variant ? variant : 'contained'}
            onClick={() => {
                if (loading || disabled || !onPress) {
                    return;
                }
                onPress();
            }}
        >
            {loading ? (
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: 'calc(50% + 3px)',
                        transform: 'translate(-50%,-50%)'
                    }}
                >
                    <ClipLoader color={Colors.white} size={22} />
                </div>
            ) : (
                label
            )}
        </ButtonWithStyles>
    );
};

const StyledButton = (
    style: CSSProperties,
    hoverStyle: CSSProperties,
    disabled: boolean
) =>
    withStyles((): unknown => ({
        root: {
            ...DefaultStyles,
            ...style,
            ...(disabled && {
                background: Colors.aliceblue,
                color: Colors.aliceblue
            }),
            '&:hover': {
                ...DefaultStyles,
                ...style,
                ...hoverStyle
            }
        }
    }))(MaterialButton);

const DefaultStyles = {
    padding: '12px 40px',
    border: 'none',
    borderRadius: 30,
    fontFamily: 'Futura-Heavy',
    fontSize: 14,
    textTransform: 'none',
    background: Colors.primary,
    color: Colors.white
} as CSSProperties;
