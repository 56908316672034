import { Colors } from 'src/utils';
import { CSSProperties, TouchEvent, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { isMobile } from 'react-device-detect';

import {
    createAvailabilitiesUpdateCache,
    removeAvailabilitiesUpdateCache
} from './updateAvailabilityCache';
import {
    useEventByIdQuery,
    useAvailabilitiesByDateQuery,
    useCreateAvailabilitiesMutation,
    useRemoveAvailabilitiesMutation
} from 'src/graphql/generated/artifacts';
import { useIsDarkMode, useUser } from 'src/hooks';

const ADDING = 'ADDING';
type ADDING = typeof ADDING;
const REMOVING = 'REMOVING';
type REMOVING = typeof REMOVING;

type TouchAction = ADDING | REMOVING;

interface Props {
    eventId: string;
    date: DateTime;
    possibleTimes: DateTime[];
}

const TimeSelectBar = (props: Props) => {
    const { eventId, date, possibleTimes } = props;
    const containerRef = useRef<HTMLInputElement>(null);
    const isDarkMode = useIsDarkMode();
    const user = useUser();
    const styles = coloredStyles(isDarkMode);

    // set of the user's available times in as strings in HH:mm format
    // we store these in a set to make checking availability faster
    const [availableTimes, setAvailableTimes] = useState<Set<string>>(
        new Set()
    );

    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [action, setAction] = useState<TouchAction>(ADDING);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [endIndex, setEndIndex] = useState<number>(0);

    const { data: eventData } = useEventByIdQuery({
        variables: { id: eventId }
    });

    const [createAvailabilities] = useCreateAvailabilitiesMutation();
    const [removeAvailabilities] = useRemoveAvailabilitiesMutation();

    const { data, loading, error } = useAvailabilitiesByDateQuery({
        variables: {
            eventId,
            date: date.toFormat('yyyy-LL-dd'),
            userIds: user ? [user.id] : []
        }
    });

    useEffect(() => {
        if (data?.availabilitiesByDate) {
            const newAvailableTimes: Set<string> = new Set(
                data.availabilitiesByDate.map(summary =>
                    summary.dateTime.slice(11, 16)
                )
            );
            setAvailableTimes(newAvailableTimes);
        }
    }, [data, possibleTimes]);

    const endSelect = () => {
        if (!isSelecting || !user) {
            return;
        }

        const selectedTimes: DateTime[] = possibleTimes.slice(
            Math.min(startIndex, endIndex),
            Math.max(startIndex, endIndex) + 1
        );

        if (action === ADDING) {
            // filter out the times the user has already marked
            // available to prevent duplicate availabilities
            const filteredTimes = selectedTimes.filter(
                (time: DateTime) => !availableTimes.has(time.toFormat('HH:mm'))
            );
            const formattedDateTimes = filteredTimes.map(
                (time: DateTime) =>
                    date.toFormat("yyyy-LL-dd'T'") + time.toFormat('HH:mm')
            );
            createAvailabilities({
                variables: {
                    eventId,
                    userId: user.id,
                    dateTimes: formattedDateTimes
                },
                update: cache => {
                    createAvailabilitiesUpdateCache(
                        cache,
                        eventId,
                        user,
                        date.toFormat('yyyy-LL-dd'),
                        formattedDateTimes
                    );
                }
            })
                .then(() =>
                    filteredTimes.forEach((time: DateTime) =>
                        availableTimes.add(time.toFormat('HH:mm'))
                    )
                )
                .finally(() => setIsSelecting(false))
                .catch(err => console.error(err));
        } else {
            const formattedDateTimes = selectedTimes.map(
                (time: DateTime) =>
                    date.toFormat("yyyy-LL-dd'T'") + time.toFormat('HH:mm')
            );
            removeAvailabilities({
                variables: {
                    eventId,
                    userId: user.id,
                    dateTimes: formattedDateTimes
                },
                update: cache => {
                    removeAvailabilitiesUpdateCache(
                        cache,
                        eventId,
                        user.id,
                        date.toFormat('yyyy-LL-dd'),
                        formattedDateTimes
                    );
                }
            })
                .then(() =>
                    selectedTimes.forEach((time: DateTime) =>
                        availableTimes.delete(time.toFormat('HH:mm'))
                    )
                )
                .finally(() => setIsSelecting(false))
                .catch(err => console.error(err));
        }
    };

    const handleSelect = (currentIndex: number) => {
        if (!isSelecting) {
            return;
        }
        setEndIndex(currentIndex);
    };

    const handleFirstSelect = (index: number) => {
        if (isSelecting) {
            return;
        }
        setIsSelecting(true);
        setStartIndex(index);
        setEndIndex(index);

        const isAvailableAtCurrentTimeBlock = availableTimes.has(
            possibleTimes[index].toFormat('HH:mm')
        );

        if (isAvailableAtCurrentTimeBlock) {
            setAction(REMOVING);
        } else {
            setAction(ADDING);
        }
    };

    /**
     * This function is only called on mobile devices, where the
     * onMouseOver event doesn't get called. Instead, we calculate
     * the index the finger is dragging over using a ref to the
     * component's container.
     */
    const onTouch = (e: TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!containerRef?.current) {
            return;
        }

        const boundingRect = containerRef.current.getBoundingClientRect();
        const topOffset = boundingRect.top;
        const diffY = e.touches[0].clientY - topOffset;
        const i = Math.round(diffY / 9);

        if (i >= 0 && i < possibleTimes.length && i !== endIndex)
            handleSelect(i);
    };

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            {eventData?.eventById?.usesWeekdays ? (
                <DateSpacer />
            ) : (
                <p style={styles.dateText}>{date.toFormat('LLL dd')}</p>
            )}
            <p style={styles.dayText}>{date.toFormat('ccc')}</p>
            <div
                ref={containerRef}
                style={styles.timeSelectContainer}
                onMouseUp={endSelect}
                onMouseLeave={endSelect}
                onTouchMove={onTouch}
            >
                {possibleTimes.slice(0, -1).map((time: DateTime, i: number) => {
                    const borderStyle = getBorderStyle(time, i);
                    const isAvailable = availableTimes.has(
                        time.toFormat('HH:mm')
                    );
                    const isSelected =
                        isSelecting &&
                        i >= Math.min(startIndex, endIndex) &&
                        i <= Math.max(startIndex, endIndex);

                    return (
                        <div
                            key={i}
                            onTouchStart={e => {
                                e.preventDefault();
                                handleFirstSelect(i);
                            }}
                            onTouchEnd={e => {
                                e.preventDefault();
                                endSelect();
                            }}
                            onMouseOver={() => handleSelect(i)}
                            onMouseDown={e => {
                                e.preventDefault();
                                if (!isMobile) handleFirstSelect(i);
                            }}
                            style={{
                                ...styles.timeBlock,
                                ...borderStyle,
                                background: isSelected
                                    ? action === ADDING
                                        ? styles.availableColor
                                        : styles.unavailableColor
                                    : isAvailable
                                    ? styles.availableColor
                                    : styles.unavailableColor
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const getBorderStyle = (time: DateTime, index: number) => {
    switch (time.toFormat('mm')) {
        case '00':
            return index === 0
                ? {}
                : ({
                      borderTopStyle: 'solid',
                      borderTopWidth: 1
                  } as CSSProperties);
        case '30':
            return {
                borderTopStyle: 'dotted',
                borderTopWidth: 1
            } as CSSProperties;
        default:
            return { height: 9 };
    }
};

const DateSpacer = () => <div style={{ height: 12 }} />;

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        width: 43,
        textAlign: 'center',
        display: 'inline-block',
        verticalAlign: 'center',
        margin: '5px 4px 10px 4px'
    } as CSSProperties,
    dateText: {
        fontFamily: 'Futura-Book',
        fontSize: 10,
        color: isDarkMode ? Colors.white : '#3C3C3C',
        margin: 0,
        lineHeight: '1.2'
    } as CSSProperties,
    dayText: {
        fontFamily: 'Futura-Heavy',
        fontSize: 18,
        color: isDarkMode ? Colors.white : '#3C3C3C',
        margin: '0 0 5px 0',
        lineHeight: '1.2'
    } as CSSProperties,
    timeSelectContainer: {
        width: '100%',
        border: `1px solid ${isDarkMode ? Colors.dusk : Colors.black}`,
        touchAction: 'pan-x'
    } as CSSProperties,
    timeBlock: {
        width: '100%',
        height: 8,
        position: 'relative',
        borderColor: isDarkMode ? Colors.dusk : Colors.black
    } as CSSProperties,
    availableColor: Colors.darkpurple,
    unavailableColor: isDarkMode ? Colors.londonhue : Colors.purplishwhite
});

export default TimeSelectBar;
