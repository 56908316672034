import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import AboutScreen from './screens/About';
import ContactScreen from './screens/Contact';
import CreateEventScreen from './screens/CreateEvent';
import EventScreen from './screens/Event';
import HomeScreen from './screens/Home';
import PrivacyScreen from './screens/Privacy';
import TermsScreen from './screens/Terms';
import TutorialScreen from './screens/Tutorial';
import UpdatesScreen from './screens/Updates';
import { setIsDarkMode } from './state/reactiveVars';

const App = () => {
    useEffect(() => {
        const isDarkModeLocal = localStorage.getItem('isDarkMode');
        setIsDarkMode(isDarkModeLocal === 'true');
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/create-event" element={<CreateEventScreen />} />
                <Route path="/tutorial" element={<TutorialScreen />} />
                <Route path="/about" element={<AboutScreen />} />
                <Route path="/updates" element={<UpdatesScreen />} />
                <Route path="/contact" element={<ContactScreen />} />
                <Route path="/privacy" element={<PrivacyScreen />} />
                <Route path="/terms" element={<TermsScreen />} />
                <Route path="/:eventId" element={<EventScreen />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
