import 'antd/lib/modal/style';

import { connectModal } from 'redux-modal';
import { Modal } from 'antd';
import { ReactNode, useCallback, useState } from 'react';

import { BeatLoader } from 'react-spinners';
import { Colors } from 'src/utils';
import { Text } from 'src/SharedComponents';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

type Props = {
    header?: string | ReactNode;
    text?: string;
    handleConfirm?: () => Promise<void>;
    handleHide: () => void;
};

const ConfirmModal = ({ header, text, handleConfirm, handleHide }: Props) => {
    const isDarkMode = useIsDarkMode();
    const [isLoading, setLoading] = useState<boolean>(false);

    const confirm = useCallback(async () => {
        if (!handleConfirm) {
            return;
        }

        setLoading(true);

        await handleConfirm();

        setLoading(false);
        handleHide();
    }, [handleConfirm, handleHide]);

    return (
        <Modal
            visible={true}
            onCancel={handleHide}
            onOk={() => {
                void confirm();
            }}
            centered
            width={600}
            className={
                isDarkMode ? 'vailable_antd_modal_dark' : 'vailable_antd_modal'
            }
            closeIcon={<></>}
            footer={
                isLoading ? (
                    <div>
                        {' '}
                        <BeatLoader color={Colors.primary} size={10} />
                    </div>
                ) : undefined
            }
        >
            <div>
                <Text
                    larger
                    bolder
                    color={isDarkMode ? Colors.white : Colors.offBlack}
                >
                    {header}
                </Text>
                <br />
                <Text
                    style={{
                        color: isDarkMode ? Colors.white : Colors.darkgray,
                        marginTop: 10
                    }}
                >
                    {text}
                </Text>
            </div>
        </Modal>
    );
};

export default connectModal({ name: 'ConfirmModal' })(ConfirmModal);
