import './styles.css';

import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

type TextAlign = 'end' | 'left' | 'center' | 'right' | 'start' | undefined;

interface Props {
    children: ReactNode;

    // style overrides
    className?: string;
    style?: CSSProperties;

    // font sizing
    xxxxlarge?: boolean;
    xxxlarge?: boolean;
    xxlarge?: boolean;
    xlarge?: boolean;
    larger?: boolean;
    large?: boolean;
    small?: boolean;
    xsmall?: boolean;

    // font weights
    bolder?: boolean;
    bold?: boolean;
    lighter?: boolean;

    // alignment
    center?: boolean;

    // font style & decorations
    color?: string;
    underline?: boolean;
    lineThrough?: boolean;
    staticPurpleUnderline?: boolean; // for this to work purpleUnderline must also be enabled

    // animated hover effects
    purpleUnderline?: boolean;
    purpleHover?: boolean;
    darkHover?: boolean;

    // actions
    onPress?: () => void;
}

export const Text: FunctionComponent<Props> = props => {
    const {
        xxxxlarge,
        xxxlarge,
        xxlarge,
        xlarge,
        larger,
        large,
        small,
        xsmall,
        center,
        bolder,
        bold,
        lighter,
        color,
        underline,
        lineThrough,
        staticPurpleUnderline,
        purpleUnderline,
        purpleHover,
        darkHover
    } = props;

    const isDarkMode = useIsDarkMode();

    let fontSize = 16;
    let textAlign = 'none' as TextAlign;
    let fontFamily = 'Futura-Book';
    let fontStyle = 'normal';

    if (xsmall) {
        fontSize = 12;
    }
    if (small) {
        fontSize = 14;
    }
    if (large) {
        fontSize = 18;
    }
    if (larger) {
        fontSize = 26;
    }
    if (xlarge) {
        fontSize = 30;
    }
    if (xxlarge) {
        fontSize = 36;
    }
    if (xxxlarge) {
        fontSize = 42;
    }
    if (xxxxlarge) {
        fontSize = 48;
    }
    if (center) {
        textAlign = 'center';
    }
    if (bolder) {
        fontFamily = 'Futura-Heavy';
    }
    if (bold) {
        fontFamily = 'Futura-Medium';
    }
    if (lighter) {
        fontFamily = 'Futura-Light';
    }
    if (underline) {
        fontStyle = 'underline';
    }
    if (lineThrough) {
        fontStyle = 'line-through';
    }

    return (
        <span
            onClick={() => {
                if (props.onPress) {
                    props.onPress();
                }
            }}
            style={{
                color: color || (isDarkMode ? Colors.white : Colors.darkgray),
                fontSize: fontSize,
                textAlign: textAlign,
                fontFamily: fontFamily,
                textDecoration: fontStyle,
                display: 'inline-block',
                ...props.style
            }}
            className={
                (props.className ?? '') +
                (purpleUnderline ? ' purple_underline' : '') +
                (purpleHover ? ' purple_hover' : '') +
                (darkHover ? ' dark_hover' : '') +
                (staticPurpleUnderline ? ' static_purple_underline' : '')
            }
        >
            {props.children}
        </span>
    );
};
