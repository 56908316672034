import { CSSProperties, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useNavigate } from 'react-router-dom';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';

const InfoBox = () => {
    const navigate = useNavigate();
    const [expanded, setExpand] = useState<boolean>(false);
    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);

    return (
        <div style={styles.container}>
            <Text large style={styles.text}>
                <i
                    className={
                        expanded ? 'fas fa-times' : 'fas fa-chevron-right'
                    }
                    style={styles.headerIcon}
                    onClick={() => setExpand(!expanded)}
                />
                How does this work?
            </Text>
            <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
                <div style={styles.info}>
                    <Text style={styles.text}>
                        <p>
                            If you are trying to plan an event (ie. meeting,
                            birthday party, office hours), you can create an
                            event using the form on this page.
                        </p>
                        <p>
                            Once you create the event, we will generate a unique
                            link for your event, which will allow you and your
                            attendees to fill in your available times.
                        </p>
                        <p>
                            The generated link will always be updated with the
                            latest submitted available times. For a more
                            detailed demo, check out our{' '}
                            <Text
                                onPress={() => navigate(`/tutorial`)}
                                color={Colors.secondary}
                                className="hover-darken"
                                style={{ cursor: 'pointer' }}
                            >
                                tutorial.
                            </Text>
                        </p>
                    </Text>
                </div>
            </AnimateHeight>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: '20px 30px 20px 30px',
        textAlign: 'left',
        background: isDarkMode ? Colors.night : Colors.backgroundgray,
        borderBottom: '1px solid',
        borderColor: isDarkMode ? Colors.dusk : 'rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    headerIcon: {
        cursor: 'pointer',
        marginRight: 20
    } as CSSProperties,
    info: {
        width: '100%',
        padding: '20px 30px 10px 35px'
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties
});

export default InfoBox;
