import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const PrivacyScreen = () => {
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            Privacy{' '}
                            <span style={{ color: Colors.secondary }}>
                                Policy
                            </span>
                        </Text>
                        <br />
                        <Text large style={styles.mainSubHeaderText}>
                            Updated on{' '}
                            <span style={{ color: Colors.secondary }}>
                                October 2, 2020
                            </span>
                        </Text>
                        <div style={styles.privacyPolicy}>
                            <Text
                                large
                                bolder
                                style={{
                                    ...styles.sectionHeaderText,
                                    marginTop: 0
                                }}
                            >
                                Introduction
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                This Statement of Privacy provides a transparent
                                description of the data and cookie policy for
                                https://www.vailable.io ("Vailable", "Site").
                                The Site is a free to use scheduling website. By
                                using Vailable, you consent to all of the data
                                and cookie practices described in this
                                statement.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Cookies
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable holds the right to use cookies of any
                                kind. These include but are not limited to
                                essential cookies, customization cookies,
                                analytics cookies, and advertising cookies.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Since Vailable uses Google AdSense services to
                                generate revenue, a variety of different
                                third-party cookies are used. The policies of
                                these cookies are beyond our control.
                                Regardless, you consent to them by using
                                Vailable services. A more detailed description
                                of Google AdSense cookies is available in the
                                following section.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Google Cookies
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Google, as a third party vendor, uses cookies to
                                serve ads on Vailable. Google's use of the DART
                                cookie enables it to serve ads to users based on
                                their visits to Vailable and other sites on the
                                internet.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                These third-party ad servers or ad networks use
                                technology on the advertisements and links that
                                appear on Vailable sent directly to your
                                browsers. They automatically receive your IP
                                address when this occurs.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Other technologies (ie. cookies, JavaScript, or
                                Web Beacons) may also be used by the third-party
                                ad networks to measure the effectiveness of
                                their advertisements and / or to personalize the
                                advertising content that you see.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable has no access or control over cookies
                                that are used by third-party ads.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                You should consult the respective privacy
                                policies of these third-party ad servers for
                                more detailed information on their practices as
                                well as instructions about how to opt-out of
                                certain practices. Vailable's privacy policy
                                does not apply to, and cannot control the
                                activities of, such other advertisers or
                                websites.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Data Collection
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Event creation and availability data are stored
                                in our databases on a private server. While we
                                do not publicly share or sell any of this data,
                                we hold the right to use the data for general
                                anonymous analytics, which may be shared
                                publicly to show active user growth.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Additionally, note that while event links are
                                unique, they are public. Thus, we strongly
                                recommend that users refrain from putting
                                sensitive/private information in event
                                descriptions.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                In the event that sensitive information is
                                released through Vailable, we are under no
                                circumstances liable. By using the Vailable
                                website, you agree to take full responsibility
                                of any such risk.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Changes to Our Policy
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable holds the right to update this
                                Statement of Privacy at any time. It is the
                                responsibility of the user to regularly check
                                for updates to our policies on this page. By
                                using the site, you confirm that you have read
                                and agree to the latest version of our Privacy
                                Policy.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Children under 13
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable does not knowingly collect personally
                                identifiable information from children under the
                                age of thirteen. If you are under the age of 18,
                                you are not permitted to visit, use, or engage
                                with the Site in any capacity.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Contact Vailable
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable is happy to discuss any questions or
                                comments regarding our Privacy Policy. If you
                                have any questions or concerns regarding
                                Vailable's adherence to this policy, contact us
                                through the contact page linked in the footer.
                            </Text>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 800,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    privacyPolicy: {
        width: '100%',
        textAlign: 'left',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 650,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack,
        marginTop: 30
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        marginTop: 10
    } as CSSProperties
});

export default PrivacyScreen;
