import { useWindowWidth } from '@react-hook/window-size';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import Snowflake from 'src/assets/icons/snowflake.svg';
import { Colors } from 'src/utils';

const BREAKPOINT = 800;
const PARTICLES_STYLE =
    process.env.REACT_APP_WINTER_MODE === 'true'
        ? {
              size: { value: 8 },
              move: {
                  enable: true,
                  direction: 'bottom-left' as const,
                  out_mode: 'out' as const
              },
              shape: {
                  type: 'images',
                  image: [{ src: Snowflake }]
              },
              opacity: {
                  value: 0.8
              }
          }
        : {
              move: { enable: true },
              size: { value: 3 },
              color: { value: Colors.primary },
              links: {
                  color: Colors.primary,
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1
              }
          };

interface Props {
    particleCount: number;
}

const ParticlesBackground = (props: Props) => {
    const { particleCount } = props;
    const windowWidth = useWindowWidth();
    return (
        <Particles
            id="tsparticles"
            init={loadFull}
            options={{
                fpsLimit: 120,
                particles: {
                    number: {
                        value:
                            windowWidth > BREAKPOINT
                                ? particleCount
                                : particleCount / 2
                    },
                    ...PARTICLES_STYLE
                }
            }}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0
            }}
        />
    );
};

export default ParticlesBackground;
