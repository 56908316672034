import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import OwlImage from 'src/assets/img/owl.png';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const ContactScreen = () => {
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            Reach{' '}
                            <span style={{ color: Colors.secondary }}>us.</span>
                        </Text>
                        <br />
                        <Text large style={styles.mainSubHeaderText}>
                            Make sure to read to the guidelines below! When
                            you’re ready, click on the lovable owl Vaila to
                            contact us, or email vailablecontact@gmail.com.
                        </Text>
                        <br />
                        <a href="mailto:vailablecontact@gmail.com?Subject=Vailable%20Contact">
                            <img
                                src={OwlImage}
                                className="hover-darken"
                                style={styles.owlImage}
                                alt=""
                            />
                        </a>
                        <div style={styles.guidelineWrap}>
                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                <span style={{ color: Colors.secondary }}>
                                    Good
                                </span>{' '}
                                reasons to contact us
                            </Text>
                            <br />
                            <div style={styles.bulletWrap}>
                                <ul>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            Reporting a bug
                                        </Text>
                                    </li>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            Requesting advertising space
                                        </Text>
                                    </li>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            UI/Function suggestions
                                        </Text>
                                    </li>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            Business inquiry
                                        </Text>
                                    </li>
                                </ul>
                            </div>
                            <br />
                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                <span style={{ color: Colors.secondary }}>
                                    Bad
                                </span>{' '}
                                reasons to contact us
                            </Text>
                            <br />
                            <div
                                style={{
                                    ...styles.bulletWrap,
                                    paddingBottom: 0
                                }}
                            >
                                <ul>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            Questions answered in tutorial
                                        </Text>
                                    </li>
                                    <li>
                                        <Text large style={styles.sectionText}>
                                            Dev/Employment opportunities
                                        </Text>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>

            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 500,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    owlImage: {
        marginTop: 40,
        width: 150,
        cursor: 'pointer'
    } as CSSProperties,
    guidelineWrap: {
        position: 'relative',
        zIndex: 1,
        width: '100%',
        textAlign: 'center',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 500,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    bulletWrap: {
        padding: '10px 0 20px 0',
        textAlign: 'left',
        display: 'inline-block'
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties
});

export default ContactScreen;
