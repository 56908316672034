// Vailable-Specific Colors
export const heliotrope = '#B044F9';
export const fadedlavender = '#C992F9';
export const darkpurple = '#912fd5';
export const purplishwhite = '#f2e3ff';

// General Monochromatic
export const white = '#fff';
export const black = '#000';
export const aliceblue = '#f3f4f5';
export const darkgray = '#707070';
export const outlinegray = '#d1d1d1';
export const offBlack = '#3A383E';
export const backgroundgray = '#f6f7fb';

// Button/Label Colors
export const gossip = '#D2FBCC';
export const puertorico = '#3EBBA1';
export const cinderella = '#FBD4D2';
export const mojo = '#C75641';

// Validation Colors
export const errorRed = '#ff0000';

// Main Theme
export const primary = heliotrope;
export const secondary = fadedlavender;

// Dark Mode Colors
export const dusk = '#424549';
export const night = '#36393e';
export const midnight = '#282b30';
export const darknight = '#1e2124';
export const londonhue = '#B4A6BF';
