import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateEventScreenshot from 'src/assets/screenshots/create_event.png';
import EventLinkScreenshot from 'src/assets/screenshots/event_link.png';
import GradientScreenshot from 'src/assets/screenshots/gradient.png';
import SubmitBarsScreenshot from 'src/assets/screenshots/submit_bars.png';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const TutorialScreen = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            Quick{' '}
                            <span style={{ color: Colors.secondary }}>
                                Tutorial
                            </span>
                        </Text>
                        <br />
                        <Text large style={styles.mainSubHeaderText}>
                            If it's your first time using Vailable, you're in
                            the right place.{' '}
                            <span role="img" aria-label="winkface">
                                😉
                            </span>
                        </Text>
                        <div style={styles.tutorialWrap}>
                            <Text
                                larger
                                bolder
                                style={{
                                    ...styles.sectionHeaderText,
                                    marginTop: 0
                                }}
                            >
                                create. share.{' '}
                                <span style={{ color: Colors.secondary }}>
                                    plan.
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                If you are planning an event, the first thing
                                you want to do is create an event on the
                                Vailable homepage. After creating an event,
                                we'll give you a special event link you can
                                share with your friends or co-workers, where
                                they will submit their availabilities.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                From there, you can just check the gradient
                                displayed at the event link to choose the best
                                meeting time. It's really that easy! No
                                downloads, no registrations, no hassle.
                            </Text>

                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                a simple{' '}
                                <span style={{ color: Colors.secondary }}>
                                    example
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Suzy needs to plan a company party next week,
                                but has no idea when to host it. She's asked
                                everyone about their free times, but there's so
                                many participants so it's hard to keep track.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                To find the best time to host the party, she
                                goes to{' '}
                                <Text
                                    onPress={() => navigate(`/`)}
                                    color={Colors.secondary}
                                    className="hover-darken"
                                    style={{ cursor: 'pointer' }}
                                >
                                    vailable.io.
                                </Text>
                            </Text>
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={CreateEventScreenshot}
                                    style={styles.tutorialScreenShotLarge}
                                    alt=""
                                />
                            </div>

                            <Text style={styles.sectionText}>
                                After she creates the event, we generate a{' '}
                                <span style={{ color: Colors.secondary }}>
                                    unique event link
                                </span>{' '}
                                for her party, which she then shares with her
                                co-workers through email, Slack, Facebook, etc.
                            </Text>
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={EventLinkScreenshot}
                                    style={styles.tutorialScreenShotSmall}
                                    alt=""
                                />
                            </div>

                            <Text style={styles.sectionText}>
                                Now, on the main event page, she enters her
                                available times by clicking and dragging on the
                                time bars. Her co-workers do the same through
                                the event link.
                            </Text>
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={SubmitBarsScreenshot}
                                    style={styles.tutorialScreenShotSmall}
                                    alt=""
                                />
                            </div>

                            <Text style={styles.sectionText}>
                                After her co-workers submit their availabilities
                                as well, she returns to the event page and sees
                                the{' '}
                                <span style={{ color: Colors.secondary }}>
                                    availability chart.
                                </span>{' '}
                                Using this chart, she is easily able to see the
                                times at which people are free, and decides to
                                host the event on March 19th 5:00pm.
                            </Text>
                            <br />
                            <Text
                                style={styles.sectionText}
                                color={Colors.secondary}
                            >
                                Everyone shows up!
                            </Text>
                            <div style={styles.screenshotWrap}>
                                <img
                                    src={GradientScreenshot}
                                    style={styles.tutorialScreenShotLarge}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 800,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    tutorialWrap: {
        width: '100%',
        textAlign: 'left',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 650,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack,
        marginTop: 40
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        marginTop: 10
    } as CSSProperties,
    screenshotWrap: {
        padding: '40px 0 40px 0',
        width: '100%',
        textAlign: 'center'
    } as CSSProperties,
    tutorialScreenShotLarge: {
        display: 'inline-block',
        borderRadius: 20,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        maxWidth: windowWidth < BREAKPOINT ? '100%' : 600
    } as CSSProperties,
    tutorialScreenShotSmall: {
        display: 'inline-block',
        borderRadius: 20,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)',
        maxWidth: windowWidth < BREAKPOINT ? '100%' : 400
    } as CSSProperties
});

export default TutorialScreen;
