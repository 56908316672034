import { useWindowWidth } from '@react-hook/window-size';
import React, { CSSProperties } from 'react';
import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode, useUser } from 'src/hooks';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';

const BREAKPOINT = 600;

interface Props {
    eventId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const EnterEdit = (props: Props) => {
    const { eventId, setStep } = props;
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const user = useUser();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    const { loading, data, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    if (loading || error) {
        return null;
    }

    return (
        <div style={styles.container}>
            <Text style={styles.text}>
                Help the event host pick an event time by submitting your
                availability. If you've already done so but want to update your
                submission, click on the edit button below.
            </Text>
            <div style={styles.buttonContainer}>
                <Button
                    label="Enter Availability"
                    onPress={() => {
                        if (!user?.id) {
                            setStep(1);
                        } else {
                            setStep(3);
                        }
                    }}
                    style={styles.enterButtonStyle}
                />
                {windowWidth < BREAKPOINT && <br />}
                {(data?.eventById?.participants ?? []).length !== 0 && (
                    <Button
                        label="Edit Availability"
                        onPress={() => {
                            if (!user?.id) {
                                setStep(2);
                            }
                        }}
                        style={styles.editButtonStyle}
                    />
                )}
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: 30,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
        textAlign: 'left'
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    buttonContainer: {
        width: '100%',
        textAlign: windowWidth > BREAKPOINT ? 'left' : 'center',
        paddingTop: 20
    } as CSSProperties,
    enterButtonStyle: {
        fontFamily: 'Futura-Medium',
        padding: '8px 16px 8px 16px'
    } as CSSProperties,
    editButtonStyle: {
        fontFamily: 'Futura-Medium',
        padding: '8px 16px 8px 16px',
        margin: windowWidth > BREAKPOINT ? '0 0 0 30px' : '20px 0 0 0'
    } as CSSProperties
});

export default EnterEdit;
