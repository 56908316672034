import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const TermsScreen = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            Our{' '}
                            <span style={{ color: Colors.secondary }}>
                                Terms
                            </span>
                        </Text>
                        <br />
                        <Text large style={styles.mainSubHeaderText}>
                            Updated on{' '}
                            <span style={{ color: Colors.secondary }}>
                                October 2, 2020
                            </span>
                        </Text>
                        <div style={styles.termsWrap}>
                            <Text
                                large
                                bolder
                                style={{
                                    ...styles.sectionHeaderText,
                                    marginTop: 0
                                }}
                            >
                                Agreement between User and
                                https://www.vailable.io
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                The https://www.vailable.io website ("Site") is
                                composed of all content and services on the
                                Site. The Site is owned and operated by Vailable
                                team members ("we", "us", "our", "Vailable"),
                                and aims to help people schedule meeting times.
                                The Site is offered to you ("user") conditioned
                                on your acceptance without modification of the
                                terms, conditions, and notices contained herein
                                ("Terms"). By using the Site in any capacity,
                                you acknowledge that you have read, understood,
                                accept and agree to the latest version of our
                                Terms. It is the responsibility of the user to
                                periodically check for updates to the Terms.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Privacy
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                The use of the Site is also goverened by our{' '}
                                <Text
                                    onPress={() => navigate(`/privacy`)}
                                    className="hover-darken"
                                    color={Colors.primary}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Privacy Policy.
                                </Text>{' '}
                                By agreeing to the Terms, you also acknowledge
                                that you have read and agree to the latest
                                version of the Vailable Privacy Policy.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Third Party Links and Advertisements
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                The Site provides links and advertisements from
                                third-party sites/services as a convenience
                                measure for users to access resources quickly.
                                You acknowledge that Vailable has no control
                                over, and is not liable or responsible for any
                                actions caused by these third-party links,
                                advertisements, and their content.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Copyright and Intellectual Property
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                You agree to not in any capacity use the Site or
                                external tools to damage, disable, overburden,
                                or impair the Site, or interfere with any other
                                party’s use and enjoyment of the Site. You may
                                not attempt to obtain any materials or
                                information through any means not intentionally
                                made available through the Site. All content on
                                the Site including text, graphics, UI, layout,
                                logos, and any software on the site are the
                                property of Vailable or its suppliers/partners,
                                and are protected by copyright and other
                                intellectual property and propriety protection
                                laws. You are prohibited from attempting to,
                                unsuccessfully or successfully, reverse engineer
                                or reproduce the Site in any capacity. You agree
                                that you cannot copy, alter, duplicate, sell,
                                publish, or display any proprietary information
                                on the Site without written permission provided
                                by the rightful owner.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Event Access and Confidentiality
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Events created on Vailable are public. If anyone
                                has the user's event url, they will have access
                                to all participants and all details of the
                                event. We are not responsible for any
                                repercussions that arise as a consequence of an
                                event being made public. It is the
                                responsibility of the user to ensure that any
                                confidential information is not added to the
                                event. If confidentiality of the event is
                                important, we strongly recommend to not create
                                an event for it on the Site.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Liability Disclaimer
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                YOU UNDERSTAND AND AGREE TO USE THE SITE AND ALL
                                OTHER ITEMS MADE AVAILABLE BY VAILABLE AT YOUR
                                OWN RISK. THE SITE AND ALL ITEMS PROVIDED BY
                                VAILABLE ARE PROVIDED ON AN “AS IS” AND “AS
                                AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND,
                                WHETHER EXPRESS, IMPLIED, STATUTORY, OR
                                OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF
                                TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                PURPOSE, OR NON-INFRINGEMENT, TO THE MAXIMUM
                                EXTENT PERMITTED BY APPLICABLE LAW. YOU HEREBY
                                ACKNOWLEDGE AND AGREE THAT UNDER NO
                                CIRCUMSTANCES WILL VAILABLE, ITS OFFICERS,
                                DIRECTORS, EMPLOYEES, AGENTS AND THIRD PARTY
                                CONTENT PROVIDERS OR LICENSORS BE LIABLE OR
                                RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR ANY
                                (i) ERRORS, MISTAKES OR INACCURACIES OF DATA OR
                                INFORMATION POSTED, DISPLAYED, PUBLISHED, OR
                                MADE AVAILABLE FOR DOWNLOAD OR USE ON THE SITE
                                (ii) PERSONAL INJURY, PROPERTY DAMAGE, OR DEATH
                                OF ANY NATURE WHATSOEVER, RESULTING FROM USE OF
                                THE SITE (iii) ANY INTERRUPTION OR CESSATION OF
                                TRANSMISSION TO OR FROM THE SITE, OR (iv) THE
                                DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY
                                THIRD PARTY NOT UNDER VAILABLE’S CONTROL. IF YOU
                                DO NOT AGREE TO THE TERMS OR ARE DISSATISFIED
                                WITH ANY PART OF THE SITE, YOUR SOLE REMEDY IS
                                TO DISCONTINUE ANY USE OF THE SITE.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Children under 13
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable does not knowingly collect personally
                                identifiable information from children under the
                                age of thirteen. If you are under the age of 18,
                                you are not permitted to visit, use, or engage
                                with the Site in any capacity.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Termination
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable reserves the right to discontinue,
                                terminate your access to the Site and its
                                services temporarily or permanently without
                                notice. You agree that Vailable is not
                                responsible or liable for any repercussions of
                                account termination whatsoever.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Modification
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable reserves the right to make
                                modifications to the Site and its services
                                without notice.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Indemnification
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                You agree to hold harmless, defend, and
                                indemnify Vailable, all officers, founders,
                                executive management, subsidiaries, licensors,
                                advertisers, merchants, licensees, directors,
                                owners, partners, agents, affiliates, advisors
                                and employees against and from all, including
                                but limited to, loss, claim, demand, costs,
                                liability, lawsuits, attorney's fees, incurred
                                by such persons or groups in conjunction with
                                any dispute or claim arising out of your breach
                                of the Terms and the Vailable Privacy Policy.
                                You agree to cooperate with Vailable fully in
                                the event of such a claim. Vailable has the
                                right, at its own costs, to fully and
                                exclusively defend itself in relation to any
                                claim of indemnification by you.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Applicable Law
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                You agree that all matters in any capacity
                                (including but not limited to disputes) relating
                                to your access to or use of the Site will be
                                governed by the laws of the United States and by
                                the laws of the State of New York. You consent
                                to the exclusive jurisdiction and venue of
                                courts in New York.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Authorization
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Use of the Site is unauthorized for users who do
                                not agree to the provision of these Terms in any
                                capacity.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                No Partnership or Employment Relationship
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                You agree that no agency, joint venture,
                                partnership, or employment is created as a
                                result of the Terms or use of the Site in any
                                way.
                            </Text>
                            <br />
                            <Text large bolder style={styles.sectionHeaderText}>
                                Contact Vailable
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable is open to answering questions about
                                the Terms through our Contact Page linked in the
                                footer of the site.
                            </Text>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 800,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    termsWrap: {
        width: '100%',
        textAlign: 'left',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 650,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack,
        marginTop: 30
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        marginTop: 10
    } as CSSProperties
});

export default TermsScreen;
