import { DateTime } from 'luxon';
import { CSSProperties, useEffect, useState } from 'react';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';

interface Props {
    possibleTimes: DateTime[];
    selectedTimezone: string;
}

const TimeLabels = (props: Props) => {
    const { possibleTimes, selectedTimezone } = props;
    const [times, setTimes] = useState<string[]>([]);
    const isDarkMode = useIsDarkMode();

    useEffect(() => {
        const newTimes = possibleTimes.map((time: DateTime) =>
            time.setZone(selectedTimezone).toFormat('h:mm a')
        );
        setTimes(newTimes);
    }, [possibleTimes, selectedTimezone]);

    const styles = coloredStyles(isDarkMode);

    return (
        <>
            {times.map((time: string, i: number) => {
                const colonIndex = time.indexOf(':');
                const minuteDigits = time.substring(
                    colonIndex + 1,
                    colonIndex + 3
                );
                /**
                 * If the first block is a time with 15, 30, or 45
                 * minute value, we offset the first label by the
                 * corresponding amount of space.
                 */
                if (i === 0) {
                    switch (minuteDigits) {
                        case '15':
                            return <div key={i} style={styles.labelOffset15} />;
                        case '30':
                            return <div key={i} style={styles.labelOffset30} />;
                        case '45':
                            return <div key={i} style={styles.labelOffset45} />;
                        default:
                            return (
                                <div key={i} style={styles.timeLabel}>
                                    <p style={styles.timeLabelText}>{time}</p>
                                </div>
                            );
                    }
                } else if (minuteDigits === '00') {
                    return (
                        <div key={i} style={styles.timeLabel}>
                            <p style={styles.timeLabelText}>{time}</p>
                        </div>
                    );
                } else return null;
            })}
        </>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    labelOffset15: {
        width: 50,
        height: 24,
        verticalAlign: 'top'
    } as CSSProperties,
    labelOffset30: {
        width: 50,
        height: 16,
        verticalAlign: 'top'
    } as CSSProperties,
    labelOffset45: {
        width: 50,
        height: 8,
        verticalAlign: 'top'
    } as CSSProperties,
    timeLabel: {
        fontFamily: 'Futura-Light',
        fontSize: 10,
        textAlign: 'right',
        width: '100%',
        height: 34
    } as CSSProperties,
    timeLabelText: {
        margin: '0 5px 0 0',
        color: isDarkMode ? Colors.white : Colors.black
    } as CSSProperties
});

export default TimeLabels;
