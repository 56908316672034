import { compose } from 'lodash/fp';
import { show } from 'redux-modal';
import { useDispatch } from 'react-redux';

export const useShowModal = () => {
    const dispatch = useDispatch();
    return compose(dispatch, show);
};

export default useShowModal;
