import { CSSProperties } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text } from 'src/SharedComponents';

interface TextPathProps {
    index: number;
    text: string;
    route: string;
}

const TextPath = (props: TextPathProps) => {
    const { route, text } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const isActive = route === location.pathname.substring(0, route.length);

    return (
        <li style={styles.textPath}>
            <div style={styles.routeWrap} onClick={() => navigate(route)}>
                <Text
                    small
                    bold
                    purpleUnderline
                    staticPurpleUnderline={isActive}
                >
                    {text}
                </Text>
            </div>
        </li>
    );
};

const styles = {
    textPath: {
        height: 'calc(100px - 60px)',
        padding: '30px 60px 20px 60px',
        display: 'inline-block',
        listStyle: 'none'
    } as CSSProperties,
    routeWrap: {
        position: 'relative',
        display: 'inline-block',
        zIndex: 0,
        cursor: 'pointer'
    } as CSSProperties
};

export default TextPath;
