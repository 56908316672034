import useCopy from '@react-hook/copy';
import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties } from 'react';
import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 600;

interface Props {
    eventId: string;
}

const CopyLink = (props: Props) => {
    const { eventId } = props;
    const eventLink = `https://vailable.io/${eventId}`;
    const windowWidth = useWindowWidth();
    const { copied, copy } = useCopy(eventLink);

    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode, copied);

    return (
        <div style={styles.container}>
            <Text style={styles.text}>
                Event Link:{' '}
                <span style={{ color: Colors.secondary }}>{eventLink}</span>
            </Text>
            <div style={styles.buttonWrap}>
                <Button
                    onPress={() => void copy()}
                    label={copied ? 'Copied!' : 'Copy Link'}
                    style={styles.buttonStyle}
                />
            </div>
        </div>
    );
};

const responsiveStyles = (
    windowWidth: number,
    isDarkMode: boolean,
    copied: boolean
) => ({
    container: {
        width: '100%',
        padding: '15px 30px 15px 30px',
        textAlign: 'left',
        background: isDarkMode ? Colors.midnight : Colors.white,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)'
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    buttonWrap: {
        display: 'inline-block',
        textAlign: 'center',
        width: BREAKPOINT < windowWidth ? 'auto' : '100%'
    } as CSSProperties,
    buttonStyle: {
        padding: 0,
        width: 110,
        height: 32,
        margin: BREAKPOINT < windowWidth ? '0 0 2px 30px' : '10px 0 0 0',
        fontFamily: 'Futura-Book',
        background: copied ? Colors.secondary : Colors.primary
    } as CSSProperties
});

export default CopyLink;
