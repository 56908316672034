import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';

const BREAKPOINT = 1000;

const HomeScreen = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.pageContent}>
                        <Text xxxxlarge bolder style={styles.headerText}>
                            Who's{' '}
                            <span style={{ color: Colors.secondary }}>
                                vailable?
                            </span>
                        </Text>
                        <br />
                        <Text larger lighter style={styles.subHeaderText}>
                            Hosting a meeting, community event, or party? Find
                            out exactly who's available and when.
                        </Text>
                        <br />
                        <Button
                            label="Get Started"
                            onPress={() => navigate(`/create-event`)}
                            style={styles.buttonStyle}
                            hoverStyle={styles.buttonHoverStyle}
                        />
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={30} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    pageContent: {
        zIndex: 1,
        textAlign: 'center',
        position: 'absolute',
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 600,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    } as CSSProperties,
    headerText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    subHeaderText: {
        marginTop: 10
    } as CSSProperties,
    buttonStyle: {
        marginTop: 30
    } as CSSProperties,
    buttonHoverStyle: {
        backgroundColor: Colors.secondary
    } as CSSProperties
});

export default HomeScreen;
