import { Dispatch, SetStateAction } from 'react';

/**
 * This function uses local storage to determine whether to
 * change the showAlertBanner state in local storage to true.
 * It basically checks if the stored npm_package_version in
 * local storage is the same as the current build's version.
 *
 * If it is the same, it will leave the showAlertBanner as
 * is. Otherwise, it will update the version in local storage,
 * and then flip showAlertBanner to true.
 */
export const updateLocalStorage = (
    setShowAlertBanner: Dispatch<SetStateAction<boolean>>
) => {
    const packageVersion = process.env.REACT_APP_VERSION;
    const localStoragePackageVersion = localStorage.getItem(
        'npm_package_version'
    );
    if (
        !localStoragePackageVersion ||
        localStoragePackageVersion !== packageVersion
    ) {
        localStorage.setItem('showAlertBanner', 'true');
        localStorage.setItem('npm_package_version', packageVersion as string);
    }
    if (localStorage.getItem('showAlertBanner') === 'true') {
        setShowAlertBanner(true);
    }
};
