import { Button } from 'src/SharedComponents';
import { CSSProperties, useState } from 'react';
import { DateTime } from 'luxon';
import TimezoneSelect from 'src/components/TimezoneSelect';

import Header from './components/Header';
import { setUser } from 'src/state/reactiveVars';
import TimeSelectInputs from './components/TimeSelectInputs';

interface Props {
    eventId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const InputSelect = (props: Props) => {
    const { eventId, setStep } = props;
    const [selectedTimezone, setSelectedTimezone] = useState<string>(
        DateTime.now().zoneName
    );

    return (
        <div style={styles.container}>
            <Header />
            <TimezoneSelect
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
            />
            <TimeSelectInputs
                eventId={eventId}
                selectedTimezone={selectedTimezone}
            />
            <div style={styles.buttonContainer}>
                <Button
                    label="Finish"
                    onPress={() => {
                        setUser(null);
                        setStep(0);
                    }}
                    style={styles.buttonStyle}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        padding: 30,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
        textAlign: 'center'
    } as CSSProperties,
    buttonContainer: {
        width: '100%',
        textAlign: 'center',
        padding: '60px 30px 30px 30px'
    } as CSSProperties,
    buttonStyle: {
        fontFamily: 'Futura-Medium',
        height: 40,
        width: 140
    } as CSSProperties
};

export default InputSelect;
