import { useCallback } from 'react';
import { Colors } from 'src/utils';
import { ReactComponent as KickIcon } from 'src/assets/icons/kick.svg';
import { removeUserFromEventCacheUpdate } from './removeUserFromEventCacheUpdate';
import styles from './styles.module.scss';
import { Text } from 'src/SharedComponents';
import { useIsDarkMode, useShowModal } from 'src/hooks';
import {
    useEventByIdQuery,
    useRemoveUserFromEventMutation,
    UserProfileFragment
} from 'src/graphql/generated/artifacts';

interface Props {
    eventId: string;
}

const Participants = (props: Props) => {
    const { eventId } = props;

    const showModal = useShowModal();
    const isDarkMode = useIsDarkMode();

    const { loading, data, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    const [removeUserFromEventMutation] = useRemoveUserFromEventMutation();
    const removeUserFromEvent = useCallback(
        async (eventId: string, userId: string) => {
            try {
                const result = await removeUserFromEventMutation({
                    variables: { eventId, userId },
                    update: cache => {
                        removeUserFromEventCacheUpdate(cache, eventId, userId);
                    }
                });
                if (
                    result?.errors?.length ||
                    !result?.data?.removeUserFromEvent?.success
                ) {
                    throw new Error('Error removing user from event.');
                }
            } catch (error) {
                showModal('SimpleAlertModal', {
                    header: 'Sorry 😢',
                    text: 'We had an issue kicking the participant out of your event.',
                    isError: true
                });
            }
        },
        []
    );

    if (error) {
        showModal('SimpleAlertModal', {
            header: 'Sorry 😢',
            text: 'We had an issue fetching the participants of your event.',
            isError: true
        });
    }

    if (loading || error) {
        return null;
    }

    return (
        <div className={styles['container']}>
            <div className={styles['title-wrap']}>
                <Text large className={styles['text']}>
                    Showing availability for{' '}
                    <span style={{ color: Colors.primary }}>
                        {data?.eventById?.participants.length}
                    </span>{' '}
                    users
                </Text>
            </div>
            <div className={styles['participants-wrap']}>
                <ul className={styles['participants-list']}>
                    {data?.eventById?.participants.map(
                        (participant: UserProfileFragment, i: number) => (
                            <li
                                key={i}
                                style={{
                                    marginBottom: 5,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Text className={styles['text']}>
                                    {participant.name}
                                </Text>
                                <div
                                    className={styles['kick-wrapper']}
                                    onClick={() => {
                                        showModal('ConfirmModal', {
                                            header: (
                                                <KickOutHeader
                                                    participantName={
                                                        participant.name
                                                    }
                                                />
                                            ),
                                            text: getKickOutText(
                                                participant.name,
                                                data?.eventById?.name
                                            ),
                                            handleConfirm: async () => {
                                                await removeUserFromEvent(
                                                    eventId,
                                                    participant.id
                                                );
                                            }
                                        });
                                    }}
                                >
                                    <KickIcon
                                        width="20px"
                                        height="20px"
                                        fill={
                                            isDarkMode
                                                ? Colors.white
                                                : Colors.darkgray
                                        }
                                    />
                                </div>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </div>
    );
};

const KickOutHeader = ({ participantName }: { participantName: string }) => {
    const isDarkMode = useIsDarkMode();
    return (
        <Text larger bolder color={isDarkMode ? Colors.white : Colors.offBlack}>
            Kick{' '}
            <span
                style={{
                    color: Colors.primary
                }}
            >
                {participantName}
            </span>{' '}
            out?
        </Text>
    );
};

const getKickOutText = (participantName: string, eventName?: string) =>
    `Are you sure you want to kick ${participantName} out of ${
        eventName ?? 'this event'
    }? This will remove all of their availability data as well.`;

export default Participants;
