import { CSSProperties } from 'react';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { useIsDarkMode } from 'src/hooks';

const Header = () => {
    const isDarkMode = useIsDarkMode();
    const styles = coloredStyles(isDarkMode);
    return (
        <div style={styles.headerContainer}>
            <Text large bold style={styles.headerText}>
                People's Availability
            </Text>
            <br />
            <Text style={{ ...styles.text, marginTop: 10 }}>
                Here is a color gradient displaying availabilities for this
                event. The darker the color, the more people available!
            </Text>
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    headerContainer: {
        width: '100%',
        maxWidth: 450,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    headerText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties
});

export default Header;
