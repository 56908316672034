import { ApolloCache } from '@apollo/client';
import { EventByIdQuery } from 'src/graphql/generated/artifacts';

export const removeUserFromEventCacheUpdate = (
    cache: ApolloCache<unknown>,
    eventId: string,
    userId: string
) => {
    /**
     * Evict user object from the cache
     */
    const id = cache.identify({
        id: userId,
        __typename: 'User'
    });
    cache.evict({ id });
    cache.gc();

    /**
     * Update event cache
     */
    cache.modify({
        id: cache.identify({ __typename: 'Event', id: eventId }),
        fields: {
            participants(
                cachedParticipants: NonNullable<
                    EventByIdQuery['eventById']
                >['participants']
            ) {
                return cachedParticipants.filter(p => p.id !== userId);
            }
        }
    });
};
