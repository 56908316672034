import { CSSProperties, useEffect, useState } from 'react';
import { DateTime, Info } from 'luxon';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

type Weekday =
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday';

interface Props {
    selected: Date[];
    onSelect: (selectedDate: Date) => void;
}

const WeekdayPicker = (props: Props) => {
    const { selected, onSelect } = props;
    const [selectedSet, setSelectedSet] = useState<Set<Weekday>>(new Set());
    const isDarkMode = useIsDarkMode();

    useEffect(() => {
        const newSelectedSet: Set<Weekday> = new Set();
        selected.forEach((date: Date) =>
            newSelectedSet.add(
                DateTime.fromJSDate(date).toFormat('cccc') as Weekday
            )
        );
        setSelectedSet(newSelectedSet);
    }, [selected]);

    const styles = coloredStyles(isDarkMode);

    return (
        <div style={styles.container}>
            {Info.weekdays().map((weekday: string, i: number) => {
                const currentDT = DateTime.now().set({ weekday: i + 1 });
                const isSelected = selectedSet.has(weekday as Weekday);
                return (
                    <div
                        onClick={() => onSelect(currentDT.toJSDate())}
                        style={{
                            ...styles.dayContainer,
                            ...(isSelected && { background: Colors.primary })
                        }}
                        className="dark_hover"
                        key={i}
                    >
                        <Text
                            xsmall
                            bold
                            color={
                                isSelected || isDarkMode
                                    ? Colors.white
                                    : Colors.darkgray
                            }
                        >
                            {currentDT.toFormat('ccc')}
                        </Text>
                    </div>
                );
            })}
        </div>
    );
};

const coloredStyles = (isDarkMode: boolean) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    } as CSSProperties,
    dayContainer: {
        padding: 2,
        margin: 2,
        width: 40,
        background: isDarkMode ? Colors.night : Colors.aliceblue,
        borderRadius: 12,
        cursor: 'pointer',
        userSelect: 'none'
    } as CSSProperties
});

export default WeekdayPicker;
