import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AvailabilitiesByDateResponse = {
  __typename?: 'AvailabilitiesByDateResponse';
  availablePeople: Array<User>;
  dateTime: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  dates: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  participants: Array<User>;
  startTime: Scalars['String'];
  timezone: Scalars['String'];
  usesWeekdays: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createAvailabilities: SuccessResponse;
  createEvent?: Maybe<Event>;
  createEventUser: User;
  removeAvailabilities: SuccessResponse;
  removeUserFromEvent: SuccessResponse;
};


export type MutationCreateAvailabilitiesArgs = {
  dateTimes: Array<Scalars['String']>;
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  dates: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  name: Scalars['String'];
  startTime: Scalars['String'];
  timezone: Scalars['String'];
  usesWeekdays: Scalars['Boolean'];
};


export type MutationCreateEventUserArgs = {
  eventId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationRemoveAvailabilitiesArgs = {
  dateTimes: Array<Scalars['String']>;
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveUserFromEventArgs = {
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  availabilitiesByDate: Array<AvailabilitiesByDateResponse>;
  eventById?: Maybe<Event>;
};


export type QueryAvailabilitiesByDateArgs = {
  date: Scalars['String'];
  eventId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryEventByIdArgs = {
  id: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
  id
  name
}
    `;
export const AvailabilitiesByDateDocument = gql`
    query availabilitiesByDate($eventId: ID!, $date: String!, $userIds: [ID!]) {
  availabilitiesByDate(eventId: $eventId, date: $date, userIds: $userIds) {
    dateTime
    availablePeople {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useAvailabilitiesByDateQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesByDateQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      date: // value for 'date'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAvailabilitiesByDateQuery(baseOptions: Apollo.QueryHookOptions<AvailabilitiesByDateQuery, AvailabilitiesByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilitiesByDateQuery, AvailabilitiesByDateQueryVariables>(AvailabilitiesByDateDocument, options);
      }
export function useAvailabilitiesByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilitiesByDateQuery, AvailabilitiesByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilitiesByDateQuery, AvailabilitiesByDateQueryVariables>(AvailabilitiesByDateDocument, options);
        }
export type AvailabilitiesByDateQueryHookResult = ReturnType<typeof useAvailabilitiesByDateQuery>;
export type AvailabilitiesByDateLazyQueryHookResult = ReturnType<typeof useAvailabilitiesByDateLazyQuery>;
export type AvailabilitiesByDateQueryResult = Apollo.QueryResult<AvailabilitiesByDateQuery, AvailabilitiesByDateQueryVariables>;
export const CreateAvailabilitiesDocument = gql`
    mutation createAvailabilities($eventId: ID!, $userId: ID!, $dateTimes: [String!]!) {
  createAvailabilities(eventId: $eventId, userId: $userId, dateTimes: $dateTimes) {
    success
  }
}
    `;
export type CreateAvailabilitiesMutationFn = Apollo.MutationFunction<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>;

/**
 * __useCreateAvailabilitiesMutation__
 *
 * To run a mutation, you first call `useCreateAvailabilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvailabilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvailabilitiesMutation, { data, loading, error }] = useCreateAvailabilitiesMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      dateTimes: // value for 'dateTimes'
 *   },
 * });
 */
export function useCreateAvailabilitiesMutation(baseOptions?: Apollo.MutationHookOptions<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>(CreateAvailabilitiesDocument, options);
      }
export type CreateAvailabilitiesMutationHookResult = ReturnType<typeof useCreateAvailabilitiesMutation>;
export type CreateAvailabilitiesMutationResult = Apollo.MutationResult<CreateAvailabilitiesMutation>;
export type CreateAvailabilitiesMutationOptions = Apollo.BaseMutationOptions<CreateAvailabilitiesMutation, CreateAvailabilitiesMutationVariables>;
export const RemoveAvailabilitiesDocument = gql`
    mutation removeAvailabilities($eventId: ID!, $userId: ID!, $dateTimes: [String!]!) {
  removeAvailabilities(eventId: $eventId, userId: $userId, dateTimes: $dateTimes) {
    success
  }
}
    `;
export type RemoveAvailabilitiesMutationFn = Apollo.MutationFunction<RemoveAvailabilitiesMutation, RemoveAvailabilitiesMutationVariables>;

/**
 * __useRemoveAvailabilitiesMutation__
 *
 * To run a mutation, you first call `useRemoveAvailabilitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAvailabilitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAvailabilitiesMutation, { data, loading, error }] = useRemoveAvailabilitiesMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      dateTimes: // value for 'dateTimes'
 *   },
 * });
 */
export function useRemoveAvailabilitiesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAvailabilitiesMutation, RemoveAvailabilitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAvailabilitiesMutation, RemoveAvailabilitiesMutationVariables>(RemoveAvailabilitiesDocument, options);
      }
export type RemoveAvailabilitiesMutationHookResult = ReturnType<typeof useRemoveAvailabilitiesMutation>;
export type RemoveAvailabilitiesMutationResult = Apollo.MutationResult<RemoveAvailabilitiesMutation>;
export type RemoveAvailabilitiesMutationOptions = Apollo.BaseMutationOptions<RemoveAvailabilitiesMutation, RemoveAvailabilitiesMutationVariables>;
export const EventByIdDocument = gql`
    query eventById($id: ID!) {
  eventById(id: $id) {
    id
    name
    description
    startTime
    endTime
    dates
    timezone
    usesWeekdays
    participants {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useEventByIdQuery__
 *
 * To run a query within a React component, call `useEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventByIdQuery(baseOptions: Apollo.QueryHookOptions<EventByIdQuery, EventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
      }
export function useEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventByIdQuery, EventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
        }
export type EventByIdQueryHookResult = ReturnType<typeof useEventByIdQuery>;
export type EventByIdLazyQueryHookResult = ReturnType<typeof useEventByIdLazyQuery>;
export type EventByIdQueryResult = Apollo.QueryResult<EventByIdQuery, EventByIdQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($name: String!, $description: String, $startTime: String!, $endTime: String!, $timezone: String!, $usesWeekdays: Boolean!, $dates: [String!]!) {
  createEvent(
    name: $name
    description: $description
    startTime: $startTime
    endTime: $endTime
    timezone: $timezone
    usesWeekdays: $usesWeekdays
    dates: $dates
  ) {
    id
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timezone: // value for 'timezone'
 *      usesWeekdays: // value for 'usesWeekdays'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const RemoveUserFromEventDocument = gql`
    mutation removeUserFromEvent($eventId: ID!, $userId: ID!) {
  removeUserFromEvent(eventId: $eventId, userId: $userId) {
    success
  }
}
    `;
export type RemoveUserFromEventMutationFn = Apollo.MutationFunction<RemoveUserFromEventMutation, RemoveUserFromEventMutationVariables>;

/**
 * __useRemoveUserFromEventMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromEventMutation, { data, loading, error }] = useRemoveUserFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromEventMutation, RemoveUserFromEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromEventMutation, RemoveUserFromEventMutationVariables>(RemoveUserFromEventDocument, options);
      }
export type RemoveUserFromEventMutationHookResult = ReturnType<typeof useRemoveUserFromEventMutation>;
export type RemoveUserFromEventMutationResult = Apollo.MutationResult<RemoveUserFromEventMutation>;
export type RemoveUserFromEventMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromEventMutation, RemoveUserFromEventMutationVariables>;
export const CreateEventUserDocument = gql`
    mutation createEventUser($name: String!, $eventId: ID!) {
  createEventUser(name: $name, eventId: $eventId) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type CreateEventUserMutationFn = Apollo.MutationFunction<CreateEventUserMutation, CreateEventUserMutationVariables>;

/**
 * __useCreateEventUserMutation__
 *
 * To run a mutation, you first call `useCreateEventUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventUserMutation, { data, loading, error }] = useCreateEventUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCreateEventUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventUserMutation, CreateEventUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventUserMutation, CreateEventUserMutationVariables>(CreateEventUserDocument, options);
      }
export type CreateEventUserMutationHookResult = ReturnType<typeof useCreateEventUserMutation>;
export type CreateEventUserMutationResult = Apollo.MutationResult<CreateEventUserMutation>;
export type CreateEventUserMutationOptions = Apollo.BaseMutationOptions<CreateEventUserMutation, CreateEventUserMutationVariables>;
export type AvailabilitiesByDateQueryVariables = Exact<{
  eventId: Scalars['ID'];
  date: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type AvailabilitiesByDateQuery = { __typename?: 'Query', availabilitiesByDate: Array<{ __typename?: 'AvailabilitiesByDateResponse', dateTime: string, availablePeople: Array<{ __typename?: 'User', id: string, name: string }> }> };

export type CreateAvailabilitiesMutationVariables = Exact<{
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
  dateTimes: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateAvailabilitiesMutation = { __typename?: 'Mutation', createAvailabilities: { __typename?: 'SuccessResponse', success: boolean } };

export type RemoveAvailabilitiesMutationVariables = Exact<{
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
  dateTimes: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveAvailabilitiesMutation = { __typename?: 'Mutation', removeAvailabilities: { __typename?: 'SuccessResponse', success: boolean } };

export type EventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventByIdQuery = { __typename?: 'Query', eventById?: { __typename?: 'Event', id: string, name: string, description?: string | null, startTime: string, endTime: string, dates: Array<string>, timezone: string, usesWeekdays: boolean, participants: Array<{ __typename?: 'User', id: string, name: string }> } | null };

export type CreateEventMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  timezone: Scalars['String'];
  usesWeekdays: Scalars['Boolean'];
  dates: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'Event', id: string } | null };

export type RemoveUserFromEventMutationVariables = Exact<{
  eventId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveUserFromEventMutation = { __typename?: 'Mutation', removeUserFromEvent: { __typename?: 'SuccessResponse', success: boolean } };

export type UserProfileFragment = { __typename?: 'User', id: string, name: string };

export type CreateEventUserMutationVariables = Exact<{
  name: Scalars['String'];
  eventId: Scalars['ID'];
}>;


export type CreateEventUserMutation = { __typename?: 'Mutation', createEventUser: { __typename?: 'User', id: string, name: string } };
