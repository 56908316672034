import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FadeIn from 'src/components/FadeIn';
import Footer from 'src/components/Footer';
import NavBar from 'src/components/NavBar';
import ParticlesBackground from 'src/components/ParticlesBackground';
import { Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import useIsDarkMode from 'src/hooks/useIsDarkMode';

const BREAKPOINT = 850;

const AboutScreen = () => {
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <div style={styles.pageContainer}>
                <NavBar />
                <FadeIn>
                    <div style={styles.mainContent}>
                        <Text xxxlarge bolder style={styles.mainHeaderText}>
                            About{' '}
                            <span style={{ color: Colors.secondary }}>
                                vailable.
                            </span>
                        </Text>
                        <br />
                        <Text larger style={styles.mainSubHeaderText}>
                            Just so we don't feel like strangers.
                        </Text>
                        <div style={styles.aboutWrap}>
                            <Text
                                larger
                                bolder
                                style={{
                                    ...styles.sectionHeaderText,
                                    marginTop: 0
                                }}
                            >
                                Founder{' '}
                                <span style={{ color: Colors.primary }}>&</span>{' '}
                                eng{' '}
                                <span role="img" aria-label="handhello">
                                    👋
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Hey! I'm{' '}
                                <Text bold color={Colors.primary}>
                                    Jason Wu
                                </Text>
                                , the creator of Vailable. I'm a Software
                                Engineer at Snackpass and Yale Class of 2022. I
                                started this codebase in 2019 to help schedule
                                university meetings.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                If you like this project or want to get in
                                touch, visit my{' '}
                                <a
                                    href="https://jasonwu.io"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Text
                                        bold
                                        className="hover-darken"
                                        color={Colors.primary}
                                    >
                                        personal website.
                                    </Text>
                                </a>
                            </Text>
                            <br />

                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                What is{' '}
                                <span style={{ color: Colors.primary }}>
                                    vailable?{' '}
                                </span>{' '}
                                <span role="img" aria-label="thinkingface">
                                    🤔
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Co-workers are busy, friends are busy, and
                                you're busy. People have more stuff on their
                                schedule these days, which makes it hard to find
                                the best time to meet.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable is a quick tool that polls people's
                                availabilities, so you can schedule your events
                                and meetings at the perfect time.
                            </Text>
                            <br />

                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                <span style={{ color: Colors.primary }}>
                                    How
                                </span>{' '}
                                does it work?{' '}
                                <span role="img" aria-label="excitedface">
                                    😮
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                All you have to do is create an event, share the
                                event link with your friends, and let them
                                submit their available times.
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                For a more detailed description, read our quick{' '}
                                <Text
                                    bold
                                    onPress={() => navigate(`/tutorial`)}
                                    color={Colors.primary}
                                    className="hover-darken"
                                    style={{ cursor: 'pointer' }}
                                >
                                    tutorial.
                                </Text>
                            </Text>
                            <br />

                            <Text
                                larger
                                bolder
                                style={styles.sectionHeaderText}
                            >
                                <span style={{ color: Colors.primary }}>
                                    Where
                                </span>{' '}
                                it's been used{' '}
                                <span role="img" aria-label="globe">
                                    🌎
                                </span>
                            </Text>
                            <br />
                            <Text style={styles.sectionText}>
                                Vailable has already been used in college
                                campuses all over the United States. Originating
                                at Yale, Vailable has now reached campuses like
                                UC Berkeley, UC San Diego, UNSW, MIT, and NYU.
                            </Text>
                        </div>
                    </div>
                </FadeIn>
                <ParticlesBackground particleCount={10} />
            </div>
            <Footer />
        </>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    pageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        textAlign: 'center',
        background: isDarkMode ? Colors.darknight : Colors.backgroundgray
    } as CSSProperties,
    mainContent: {
        zIndex: 1,
        position: 'relative',
        paddingTop: 160,
        width: windowWidth < BREAKPOINT ? 'calc(100% - 60px)' : 650,
        textAlign: 'center',
        display: 'inline-block'
    } as CSSProperties,
    aboutWrap: {
        width: '100%',
        textAlign: 'left',
        marginTop: 60,
        marginBottom: 60,
        padding:
            windowWidth < BREAKPOINT
                ? '40px 30px 40px 30px'
                : '40px 60px 40px 60px',
        borderRadius: 25,
        background: isDarkMode ? Colors.midnight : Colors.white,
        boxShadow: '0 0 20px -10px rgba(0, 0, 0, 0.3)'
    } as CSSProperties,
    mainHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    mainSubHeaderText: {
        marginTop: 5,
        maxWidth: 650,
        color: isDarkMode ? Colors.white : Colors.darkgray
    } as CSSProperties,
    sectionHeaderText: {
        color: isDarkMode ? Colors.white : Colors.offBlack,
        marginTop: 40
    } as CSSProperties,
    sectionText: {
        color: isDarkMode ? Colors.white : Colors.darkgray,
        marginTop: 10
    } as CSSProperties
});

export default AboutScreen;
