import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import { CSSProperties, useState } from 'react';
import { Button, Text } from 'src/SharedComponents';
import { Colors } from 'src/utils';
import { setUser } from 'src/state/reactiveVars';
import useIsDarkMode from 'src/hooks/useIsDarkMode';
import { useEventByIdQuery } from 'src/graphql/generated/artifacts';
import { UserProfileFragment } from 'src/graphql/generated/artifacts';

const BREAKPOINT = 600;
const SMALL_BREAKPOINT = 400;

interface Props {
    eventId: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const SelectTempUser = (props: Props) => {
    const { eventId, setStep } = props;
    const windowWidth = useWindowWidth();
    const isDarkMode = useIsDarkMode();
    const styles = responsiveStyles(windowWidth, isDarkMode);

    const [showError, setShowError] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string>('');

    const { loading, data, error } = useEventByIdQuery({
        variables: { id: eventId }
    });

    if (loading || error) {
        return null;
    }

    const onPress = () => {
        if (selectedUserId.length === 0) {
            return setShowError(true);
        }

        const participants = data?.eventById?.participants ?? [];
        const userIndex = participants.findIndex(
            user => user.id === selectedUserId
        );

        setUser(participants[userIndex]);
        setStep(3);
    };

    return (
        <div style={styles.container}>
            <Text style={styles.text}>
                Need to update your availability data? No problem! Let us know
                who you are first by selecting your name from the dropdown menu.
            </Text>
            <div style={styles.fieldContainer}>
                <FormControl variant="standard" style={styles.field}>
                    <InputLabel>Select your name</InputLabel>
                    <Select
                        type="name"
                        name="name"
                        value={selectedUserId}
                        onChange={e => setSelectedUserId(e.target.value)}
                        style={{ textAlign: 'left' }}
                        MenuProps={{ style: { maxHeight: 600 } }}
                    >
                        {data?.eventById?.participants.map(
                            (user: UserProfileFragment, i: number) => (
                                <MenuItem
                                    key={i}
                                    value={user.id}
                                    style={styles.field}
                                >
                                    {user.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
                <br />
                {showError && (
                    <Text
                        small
                        color={Colors.errorRed}
                        style={{ marginTop: 10 }}
                    >
                        Please select who you are!
                    </Text>
                )}
            </div>
            <div style={styles.buttonContainer}>
                <Button
                    label="Continue"
                    onPress={onPress}
                    style={styles.continueButtonStyle}
                />
                {windowWidth < SMALL_BREAKPOINT && <br />}
                <Button
                    label="Back"
                    onPress={() => setStep(0)}
                    style={styles.backButtonStyle}
                />
            </div>
        </div>
    );
};

const responsiveStyles = (windowWidth: number, isDarkMode: boolean) => ({
    container: {
        width: '100%',
        padding: 30,
        borderBottom: '1px solid rgba(112, 112, 112, 0.2)',
        textAlign: 'left'
    } as CSSProperties,
    fieldContainer: {
        width: '100%',
        textAlign: windowWidth > BREAKPOINT ? 'left' : 'center',
        padding: '20px 0 10px 0'
    } as CSSProperties,
    field: {
        width: '100%',
        maxWidth: 300,
        maxHeight: 200
    } as CSSProperties,
    text: {
        color: isDarkMode ? Colors.white : Colors.offBlack
    } as CSSProperties,
    buttonContainer: {
        width: '100%',
        textAlign: windowWidth > BREAKPOINT ? 'left' : 'center',
        paddingTop: 20
    } as CSSProperties,
    continueButtonStyle: {
        fontFamily: 'Futura-Medium',
        height: 40,
        width: 140,
        padding: 0
    } as CSSProperties,
    backButtonStyle: {
        fontFamily: 'Futura-Medium',
        height: 40,
        width: 100,
        padding: 0,
        margin: windowWidth > SMALL_BREAKPOINT ? '0 0 0 30px' : '20px 0 0 0',
        background: Colors.secondary
    } as CSSProperties
});

export default SelectTempUser;
